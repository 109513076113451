import { Text, VStack, Icon, HStack, Image, Flex, Show, Box, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiCornerLeftDown, FiCornerRightDown } from 'react-icons/fi';
import { RiCheckboxCircleFill, RiCloseCircleFill } from 'react-icons/ri';

import { Colors, TextStyle } from '../../theme';
import in_1 from '../../resources/assets/landing/quality/in1.jpg';
import in_2 from '../../resources/assets/landing/quality/in2.jpeg';
import in_3 from '../../resources/assets/landing/quality/in3.jpeg';
import in_4 from '../../resources/assets/landing/quality/in4.jpeg';
import good_1 from '../../resources/assets/landing/quality/good1.jpg';
import good_2 from '../../resources/assets/landing/quality/good2.jpg';
import good_3 from '../../resources/assets/landing/quality/good3.jpg';
import bad_1 from '../../resources/assets/landing/quality/bad1.jpeg';
import bad_2 from '../../resources/assets/landing/quality/bad2.jpeg';
import bad_3 from '../../resources/assets/landing/quality/bad.jpg';

const InputPhotos = () => (
  <HStack spacing='8px'>
    <Icon
      w='24px'
      h='24px'
      as={FiCornerLeftDown}
    />
    {
      [in_1, in_2, in_3, in_4].map((item, index) => (
        <Image
          w='60px'
          h='60px'
          key={index}
          src={item}
          objectFit='cover'
          borderRadius='16px'
        />
      ))
    }
    <Icon
      w='24px'
      h='24px'
      as={FiCornerRightDown}
    />
  </HStack>
);

const BlockInfo = ({ title, images, points, icon }) => (
  <VStack
    spacing='20px'
    w='100%'
  >
    <VStack
      spacing='16px'
      w='100%'
    >
      <Text
        textStyle={TextStyle.h4}
        color={Colors.black}
      >
        { title }
      </Text>
      <SimpleGrid
        w='100%'
        spacing='16px'
        columns={3}
      >
        {
          images.map((image, index) => (
            <Image
              key={index}
              aspectRatio={3/4}
              h='auto'
              w='100%'
              borderRadius='8px'
              objectFit='cover'
              src={image}
            />
          ))
        }
      </SimpleGrid>
    </VStack>
    <Flex
      w='100%'
      gap='8px'
      flexDirection='row'
    >
      <Flex
        grow={1}
        flex={1}
        gap='16px'
        flexDirection='column'
      >
        {
          points.slice(0, 3).map((item, index) => (
            <HStack key={index} spacing='8px'>
              { icon }
              <Text
                textStyle={TextStyle.h5}
                color={Colors.black}
              >
                { item }
              </Text>
            </HStack>
          ))
        }
      </Flex>
      <Flex
        grow={1}
        flex={1}
        gap='16px'
        flexDirection='column'
      >
        {
          points.slice(3, 6).map((item, index) => (
            <HStack key={index} spacing='8px'>
              { icon }
              <Text
                textStyle={TextStyle.h5}
                color={Colors.black}
              >
                { item }
              </Text>
            </HStack>
          ))
        }
      </Flex>
    </Flex>
  </VStack>
);

const breakpointMobile = 'lg';

const Quality = () => {
  const { t } = useTranslation();

  return (
    <VStack
      w='100%'
      spacing='72px'
    >
      <VStack
        spacing='36px'
      >
        <Text textAlign='center' color={Colors.black} textStyle={TextStyle.h2Bold}>
          { t('welcome.quality.title') }
        </Text>
        <Text textAlign='center' color={Colors.black} textStyle={TextStyle.body}>
          { t('welcome.quality.detail') }
        </Text>
      </VStack>
      <VStack
        spacing='16px'
        w='100%'
      >
        <Show
          above={breakpointMobile}
        >
          <InputPhotos />
        </Show>
        <Flex
          flexDirection={{ base: 'column', [breakpointMobile]: 'row' }}
          gap='32px'
        >
          <Box
            w={{ base: '100%', [breakpointMobile]: '50%' }}
          >
            <BlockInfo
              title={t('welcome.quality.good.title')}
              images={[good_1, good_2, good_3]}
              points={[1,2,3,4,5,6].map((item) => t(`welcome.quality.good.info_${item}`))}
              icon={<Icon w='24px' h='24px' as={RiCheckboxCircleFill} color={Colors.green} />}
            />
          </Box>
          <Box
            w={{ base: '100%', [breakpointMobile]: '50%' }}
          >
            <BlockInfo
              title={t('welcome.quality.bad.title')}
              images={[bad_1, bad_2, bad_3]}
              points={[1,2,3,4,5,6].map((item) => t(`welcome.quality.bad.info_${item}`))}
              icon={<Icon w='24px' h='24px' as={RiCloseCircleFill} color={Colors.red} />}
            />
          </Box>
        </Flex>
      </VStack>
    </VStack>
  );
};

export default Quality;
import axios from 'axios';
import { Mutex } from 'async-mutex';

import { store } from '../store';
import ErrorCodes from '../helpers/errorCodes';
import { makeRefreshToken, Keys as AuthKeys, signOut } from '../slices/auth.slice';
import { NotAuthorizedError } from '../helpers/errors';
import api from './api';

const mutex = new Mutex();

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === ErrorCodes.notAuthorized
      && !!originalRequest.headers[AuthKeys.authorizationHeader]
      && !originalRequest._retry // eslint-disable-line no-underscore-dangle
      && !(originalRequest.method === 'post' && originalRequest.url === '/v1/auth/refresh-token')
    ) {
      // Logger.log(`intercept error from request ${JSON.stringify(originalRequest)}`);
      // Logger.log(`api intercept auth error`);
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        try {
          await store.dispatch(makeRefreshToken()).unwrap();
        } catch (err) {
          await store.dispatch(signOut(true));
          throw new NotAuthorizedError();
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
      }

      // Logger.log(`api resresh token finish`);
      const { auth: { token } } = store.getState();

      if (token == null) {
        throw new NotAuthorizedError();
      }

      originalRequest.headers.Authorization = `Bearer ${token}`;
      originalRequest._retry = true; // eslint-disable-line no-underscore-dangle

      return axios(originalRequest);
    }
    if (axios.isAxiosError(error)) {
      if (
        error.response
        && error.response.data
        && error.response.data.error
        && error.response.data.error.message
      ) {
        error.axiosMessage = error.message;
        error.message = error.response.data.error.message;
      }
      if (error.response && error.response.status) {
        error.status = error.response.status;
        error.axiosCode = error.code;
        error.code = error.status;
      }
    }
    return Promise.reject(error);
  },
);
const IS_TEST = process.env.NODE_ENV === 'test';
const IS_DEV = process.env.NODE_ENV === 'development';
const IS_PROD = !IS_TEST && !IS_DEV;

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  IS_TEST,
  IS_PROD,
  IS_DEV,
};

import { Input, Select, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Colors, TextStyle } from '../../theme';
import { setGender, setName, updateProject } from '../../slices/projects.slice';
import FilledButton from '../../components/FilledButton';

const ProjectGeneral = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const editingProject = useSelector((state) => state.projects.editingProject);
  const isDisabled = (!editingProject.name || editingProject.name.length === 0)
    || (!editingProject.gender || editingProject.gender.length === 0)

  return (
    <VStack
      w='100%'
      spacing='16px'
    >
      <Text
        textStyle={TextStyle.body}
        color={Colors.black}
      >
        { t('project.projectDetails.form.title') }
      </Text>
      <Input
        maxW={{ base: '80%', md: '30%' }}
        _placeholder={{ color: Colors.lightGray }}
        borderColor={Colors.lightGray}
        size='md'
        placeholder={t('project.projectDetails.name.placeholder')}
        value={editingProject.name}
        onChange={(event) => dispatch(setName(event.target.value))}
      />
      <Select
        maxW={{ base: '80%', md: '30%' }}
        _placeholder={{ color: Colors.lightGray }}
        borderColor={Colors.lightGray}
        size='md'
        placeholder={t('project.projectDetails.gender.placeholder')}
        onChange={(event) => dispatch(setGender(event.target.value))}
      >
        <option selected={editingProject.gender === 'male'} value='male'>{t('project.projectDetails.gender.male')}</option>
        <option selected={editingProject.gender === 'female'} value='female'>{t('project.projectDetails.gender.female')}</option>
      </Select>
      <FilledButton
        backgroundColor={Colors.blue}
        text={t('common.continue')}
        isDisabled={isDisabled}
        onClick={() => dispatch(updateProject())}
        isLoading={editingProject.isUpdating}
      />
    </VStack>
  );
}

export default ProjectGeneral;
import { Navigate, Route, BrowserRouter, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Provider } from 'react-redux';
import { useEffect } from 'react';
import { ChakraProvider, Flex, Spinner } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga4';

import './services/api.extension';
import './services/amplitude';
import { store } from './store';
import { AuthState, setupAuth } from './slices/auth.slice';

import { Colors, customTheme } from './theme';
import RouteIds, { PageTitles } from './helpers/RouteIds';

import CompletedProject from './routes/CompletedProject';
import Welcome from './routes/Welcome';
import Login from './routes/Login';
import Privacy from './routes/Privacy';
import ProjectsCollection from './routes/ProjectsCollection';
import Project from './routes/Project';
import DefaultLayout from './components/DefaultLayout';
import TopBar from './components/TopBar';
import Terms from './routes/Terms';
import { ToastContainer } from './components/Toast';

ReactGA.initialize('G-DLFE46XDVX');

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const LoadingIndicator = () => (
  <DefaultLayout
    backgroundColor={Colors.lightBlue}
    topBar={<TopBar backgroundColor={Colors.white} />}
    hasFooter
    footerColor={Colors.white}
  >
    <Flex
      direction='column'
      justify='center'
      align='center'
      grow={1}
    >
      <Spinner size='lg' color={Colors.black} />
    </Flex>
  </DefaultLayout>
);

const WaitingAuth = ({ children }) => {
  const authState = useSelector((state) => state.auth.authState);

  if (authState === AuthState.loading || authState === AuthState.idle) {
    return (<LoadingIndicator />);
  }

  return children;
};

const RequireAuth = ({ children }) => {
  const authState = useSelector((state) => state.auth.authState);
  let location = useLocation();

  if (authState === AuthState.loading || authState === AuthState.idle) {
    return (<LoadingIndicator />);
  }

  if (authState !== AuthState.auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={RouteIds.Welcome} state={{ from: location }} replace />;
  }

  return children;
};

const RequireNotAuth = ({ children }) => {
  const authState = useSelector((state) => state.auth.authState);
  let location = useLocation();

  if (authState === AuthState.loading || authState === AuthState.idle) {
    return (<LoadingIndicator />);
  }

  if (authState !== AuthState.auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return children;
  }

  return <Navigate to={RouteIds.App} state={{ from: location }} replace />;
};

const PageGoogleAnalytics = () => {
  let location = useLocation();
  
  useEffect(() => {
    // console.log(window.location.pathname + window.location.search)
    const path = window.location.pathname + window.location.search;
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: PageTitles[path] || path
    });
  }, [location]);

  return null;
};

// In dev useeffect called twice at begin
let prevAuthState;

const InsideRedux = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth.authState);

  useEffect(() => {
    if (authState === AuthState.idle && authState !== prevAuthState) {
      dispatch(setupAuth());
    }
    prevAuthState = authState;
  }, [dispatch, authState]);

  return (
    <>
      <BrowserRouter>
        <PageGoogleAnalytics />
        <ScrollToTop />
        <Routes>
          <Route path={RouteIds.Welcome} element={<Welcome />} />
          <Route
            path={RouteIds.Login}
            element={
              <RequireNotAuth>
                <Login />
              </RequireNotAuth>
            }
          />
          <Route path={RouteIds.Privacy} element={<Privacy />} />
          <Route path={RouteIds.Terms} element={<Terms />} />
          <Route
            path={RouteIds.CompletedProject}
            element={
              <WaitingAuth>
                <CompletedProject />
              </WaitingAuth>
            }
          />
          <Route
            path={RouteIds.App}
            element={
              <RequireAuth>
                <ProjectsCollection />
              </RequireAuth>
            }
          />
          <Route
            path={RouteIds.Project}
            element={
              <RequireAuth>
                <Project />
              </RequireAuth>
            }
          />
          <Route path='/*' element={<Navigate replace to={RouteIds.App} />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
};

function App() {
  return (
    <GoogleOAuthProvider clientId='179346765880-ck7tusud9q5eg7sm2goinhlce2h09lud.apps.googleusercontent.com'>
      <Provider store={store}>
        <ChakraProvider theme={customTheme}>
          <InsideRedux />
        </ChakraProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;

import i18n from '../helpers/i18n';
import { Colors } from '../theme';

export const ProjectStatus = {
  completed: 'completed',
  processing: 'processing',
  waitingForPay: 'waiting_for_pay',
  waitingForPhotos: 'waiting_for_photos',
  photoRules: 'photo_rules',
  projectDetails: 'project_details'
};

export const nextAfterWaitingForPay = ProjectStatus.processing;

export const nextProjectStatus = (status) => {
  switch (status) {
    case ProjectStatus.waitingForPay:
      // if (vars.IS_DEV) {
      //   return ProjectStatus.waitingForSubmit;
      // }
      return ProjectStatus.waitingForPay;
    case ProjectStatus.waitingForPhotos:
      return ProjectStatus.waitingForPay;
    case ProjectStatus.photoRules:
      return ProjectStatus.waitingForPhotos;
    case ProjectStatus.projectDetails:
      return ProjectStatus.photoRules;
    default: return ProjectStatus.projectDetails;
  }
};

export const titleOfProjectStatus = (status) => {
  switch (status) {
    case ProjectStatus.completed:
      return i18n.t('projectStatus.completed');
    case ProjectStatus.processing:
      return i18n.t('projectStatus.processing');
    case ProjectStatus.waitingForPay:
      return i18n.t('projectStatus.waitingForPay');
    case ProjectStatus.waitingForPhotos:
      return i18n.t('projectStatus.waitingForPhotos');
    default: return i18n.t('projectStatus.waitingForPhotos');
  }
}

export const colorOfProjectStatus = (status) => {
  switch (status) {
    case ProjectStatus.completed:
      return Colors.green;
    case ProjectStatus.processing:
      return Colors.yellow;
    case ProjectStatus.waitingForPay:
    case ProjectStatus.waitingForPhotos:
      return Colors.blue;
    default: return Colors.blue;
  }
}
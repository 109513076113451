import { Box, Flex, Grid, GridItem, HStack, Hide, Image, Show, Spacer, Text, VStack, useBreakpoint } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Colors, TextStyle } from '../../theme';

import LeftArrow from '../../resources/assets/landing/firstSection/leftArrow.svg';
import RightArrow from '../../resources/assets/landing/firstSection/rightArrow.svg';
import TopLeftGenerated from '../../resources/assets/landing/firstSection/gen4.jpg';
import TopLeftReal from '../../resources/assets/landing/firstSection/real4.jpg';
import BottomLeftGenerated from '../../resources/assets/landing/firstSection/gen2.jpg';
import BottomLeftReal from '../../resources/assets/landing/firstSection/real2.jpg';
import TopRightGenerated from '../../resources/assets/landing/firstSection/gen1.jpg';
import TopRightReal from '../../resources/assets/landing/firstSection/real1.jpg';
import BottomRightGenerated from '../../resources/assets/landing/firstSection/gen3.jpg';
import BottomRightReal from '../../resources/assets/landing/firstSection/real3.jpg';
import FilledButton from '../../components/FilledButton';
import { chakraMultilineText } from '../../helpers/chakraUtils';
import { useNavigate } from 'react-router-dom';
import RouteIds from '../../helpers/RouteIds';
import EmailJoin from './EmailJoin';
import WebAndMobileLayout from '../../components/WebAndMobileLayout';

const PhotoWithLabel = ({ bigImage, marginLeft, children, marginRight, width, minWidthImage }) => {
  const { t } = useTranslation();

  return (
    <Flex
      width={width || '58%'}
      direction='column'
      alignItems='center'
      marginLeft={marginLeft}
      marginRight={marginRight}
      gap='4px'
    >
      <Box
        width='100%'
        height='auto'
        aspectRatio={120 / 154}
        minWidth={minWidthImage}
        position='relative'
      >
        <Image
          w='100%'
          h='100%'
          src={bigImage}
          borderRadius='16px'
          objectFit='fill'
        />
        { children }
      </Box>
      <Text
        textStyle={TextStyle.footnote}
        color={Colors.lightGray}
      >
        { t('welcome.introSection.generatedAI') }
      </Text>
    </Flex>
  );
};

const PhotoWithArrow = ({ littleImage, isLeft }) => (
  <Flex
    direction='column'
    alignItems='flex-start'
    position='absolute'
    top={0}
    left={0}
    right={0}
    bottom={0}
  >
    <Image
      mt='5%'
      width='auto'
      height='50%'
      aspectRatio={59 / 76}
      src={littleImage}
      ml={isLeft ? '-28%' : '75%'}
      border='2px'
      borderColor={Colors.white}
      borderRadius='8px'
    />
    <Image
      src={isLeft ? LeftArrow : RightArrow}
      aspectRatio={isLeft ? 16.77 / 26.63 : 11.74 / 25.29}
      height='13%'
      width='auto'
      objectFit='cover'
      ml={isLeft ? '-13%' : '105%'}
    />
  </Flex>
);

const GeneratedLeftTopPhoto = ({ bigImage, littleImage }) => (
  <Flex
    aspectRatio={1}
    height='auto'
    justifyContent='flex-end'
    width='100%'
    direction='column'
  >
    <PhotoWithLabel
      bigImage={bigImage}
      marginLeft='18%'
    >
      <PhotoWithArrow
        littleImage={littleImage}
        isLeft
      />
    </PhotoWithLabel>
  </Flex>
);

const GeneratedLeftBottomPhoto = ({ bigImage, littleImage }) => (
  <Flex
    aspectRatio={1}
    height='auto'
    justifyContent='flex-end'
    width='100%'
    direction='column'
    alignItems='flex-end'
  >
    <PhotoWithLabel
      bigImage={bigImage}
    >
      <PhotoWithArrow
        littleImage={littleImage}
        isLeft
      />
    </PhotoWithLabel>
    <Spacer />
  </Flex>
);

const GeneratedRightTopPhoto = ({ bigImage, littleImage }) => (
  <Flex
    aspectRatio={1}
    height='auto'
    justifyContent='flex-end'
    width='100%'
    direction='column'
    alignItems='flex-end'
  >
    <PhotoWithLabel
      bigImage={bigImage}
      marginRight='18%'
    >
      <PhotoWithArrow
        littleImage={littleImage}
      />
    </PhotoWithLabel>
  </Flex>
);

const GeneratedRightBottomPhoto = ({ bigImage, littleImage }) => (
  <Flex
    aspectRatio={1}
    height='auto'
    width='100%'
    direction='column'
  >
    <PhotoWithLabel
      bigImage={bigImage}
    >
      <PhotoWithArrow
        littleImage={littleImage}
      />
    </PhotoWithLabel>
  </Flex>
);

const MiddleSection = ({ h }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Flex
      w='100%'
      h={h}
      direction='column'
      justifyContent='center'
      alignItems='center'
      gap='32px'
    >
      <Text
        color={Colors.black}
        textStyle={TextStyle.h1Bold}
        textAlign='center'
        maxWidth='600px'
      >
        { t('welcome.introSection.title') }
      </Text>
      <Text
        color={Colors.lightBlack}
        textStyle={TextStyle.h5}
        lineHeight={1.5}
        textAlign='center'
        maxWidth='500px'
      >
        { chakraMultilineText(t('welcome.introSection.detail')) }
      </Text>
      <FilledButton
        text={t('welcome.introSection.button')}
        onClick={() => navigate(RouteIds.Login)}
      />
    </Flex>
  );
};

const breakpointMobile = 'lg';

const Gallery = ({ topImage, topLittleImage, bottomImage, bottomLittleImage, isLeft }) => (
  <Flex
    direction='column'
    flexGrow={0}
    justifyContent='flex-start'
    gap='68px'
  >
    {
      (isLeft)
      ? (
        <>
          <GeneratedLeftTopPhoto
            bigImage={topImage}
            littleImage={topLittleImage}
          />
          <GeneratedLeftBottomPhoto
            bigImage={bottomImage}
            littleImage={bottomLittleImage}
          />
        </>
      )
      : (
        <>
          <GeneratedRightTopPhoto
            bigImage={topImage}
            littleImage={topLittleImage}
          />
          <GeneratedRightBottomPhoto
            bigImage={bottomImage}
            littleImage={bottomLittleImage}
          />
        </>
      )
    }
  </Flex>
);

const IntroSection = () => (
  <WebAndMobileLayout
    breakpoint={breakpointMobile}
    web={
      <Grid
        columnGap={{ base: '8px', md: '16px', xl: '20px' }}
        templateColumns='repeat(12, 1fr)'
        templateRows='1fr'
        w='100%'
      >
        <GridItem
          colSpan={2}
        >
          <Gallery
            topImage={TopLeftGenerated}
            topLittleImage={TopLeftReal}
            bottomImage={BottomLeftGenerated}
            bottomLittleImage={BottomLeftReal}
            isLeft
          />
        </GridItem>
        <GridItem
          colStart={3}
          colSpan={8}
        >
          <MiddleSection h='100%' />
        </GridItem>
        <GridItem
          colStart={11}
          colSpan={2}
        >
          <Gallery
            topImage={TopRightGenerated}
            topLittleImage={TopRightReal}
            bottomImage={BottomRightGenerated}
            bottomLittleImage={BottomRightReal}
          />
        </GridItem>
      </Grid>
    }
    mobile={
      <VStack
        spacing='48px'
        w='100%'
      >
        <MiddleSection />
        <HStack
          spacing={{ base: '8px', md: '16px'}}
          whiteSpace='nowrap'
          overflowX='auto'
          justifyContent='flex-start'
          w='100%'
        >
          {
            [TopLeftGenerated, BottomLeftGenerated, TopRightGenerated, BottomRightGenerated].map((item, index) => (
              <PhotoWithLabel
                key={index}
                width='auto'
                bigImage={item}
                minWidthImage='108px'
              />
            ))
          }
        </HStack>
      </VStack>
    }
  />
);

export default IntroSection;
import { Grid, GridItem, Text, VStack, Icon, HStack, Image, keyframes } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Colors, TextStyle } from '../../theme';
import img_1 from '../../resources/assets/landing/notReal/1.jpg';
import img_2 from '../../resources/assets/landing/notReal/2.jpg';
import img_3 from '../../resources/assets/landing/notReal/3.jpg';
import img_4 from '../../resources/assets/landing/notReal/4.jpg';
import img_5 from '../../resources/assets/landing/notReal/5.jpg';
import img_6 from '../../resources/assets/landing/notReal/6.jpg';
import img_7 from '../../resources/assets/landing/notReal/7.jpg';
import img_8 from '../../resources/assets/landing/notReal/8.jpg';
import img_9 from '../../resources/assets/landing/notReal/9.jpg';
import img_10 from '../../resources/assets/landing/notReal/10.jpg';
import img_11 from '../../resources/assets/landing/notReal/11.jpg';
import img_12 from '../../resources/assets/landing/notReal/12.jpg';
import img_13 from '../../resources/assets/landing/notReal/13.jpg';
import img_14 from '../../resources/assets/landing/notReal/14.jpg';
import img_15 from '../../resources/assets/landing/notReal/15.jpg';
import img_16 from '../../resources/assets/landing/notReal/16.jpg';
import img_17 from '../../resources/assets/landing/notReal/17.jpg';
import img_18 from '../../resources/assets/landing/notReal/18.jpg';
import img_19 from '../../resources/assets/landing/notReal/19.jpg';
import img_20 from '../../resources/assets/landing/notReal/20.jpg';
import img_21 from '../../resources/assets/landing/notReal/21.jpg';

const scroll = keyframes({
  '0%': {
    transform: 'translateX(0%)'
  },
  '100%': {
    transform: 'translateX(-100%)'
  }
});

const reverseScroll = keyframes({
  '0%': {
    transform: 'translateX(-100%)'
  },
  '100%': {
    transform: 'translateX(0%)'
  }
});

const Gallery = ({ images, reversed }) => (
  <HStack
    spacing={{ base: '8px', md: '16px'}}
    whiteSpace='nowrap'
    overflowX='auto'
    justifyContent='flex-start'
    w='100%'
  >
    <HStack animation={`${reversed ? reverseScroll : scroll} 20s linear infinite`}>
    {
      images.map((item, index) => (
        <Image
          key={index}
          width='auto'
          aspectRatio={3/4}
          h={{ base: '300px', lg: '400px' }}
          src={item}
          objectFit='cover'
          
        />
      ))
    }
    </HStack>
  </HStack>
);

const NotRealPhotos = () => {
  const { t } = useTranslation();

  const topImages = [
    img_1,
    img_2,
    img_4,
    img_5,
    img_7,
    img_8,
    img_6,
    img_9,
    img_10,
    img_11,
    img_12,
    img_13,
    img_14,
    img_15,
    img_16,
    img_17,
    img_3,
    img_18,
    img_19,
    img_20,
    img_21,
  ];

  const bottomImages = [].concat(topImages).reverse();
  

  return (
    <VStack
      w='100%'
      spacing='72px'
    >
      <Text textAlign='center' color={Colors.black} textStyle={TextStyle.h2Bold}>
        { t('welcome.notRealPhotos.title') }
      </Text>
      <VStack
        w='100%'
        spacing={{ base: '8px', md: '16px'}}
      >
        <Gallery images={topImages} />
        <Gallery images={bottomImages} reversed />
      </VStack>
    </VStack>
  );
};

export default NotRealPhotos;
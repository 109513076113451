import { Checkbox, Flex, Image, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { Colors, TextStyle } from '../../theme';
import ChakraStyleTagText from '../../components/ChakraStyleTagText';
import FilledButton from '../../components/FilledButton';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProject } from '../../slices/projects.slice';
import { ProjectStatus } from '../../models/ProjectStatus';
import { useNavigate, useNavigation } from 'react-router-dom';
import RouteIds from '../../helpers/RouteIds';
import bad_1 from '../../resources/assets/project/rules/bad_1.jpg';
import bad_2 from '../../resources/assets/project/rules/bad_2.jpg';
import bad_3 from '../../resources/assets/project/rules/bad_3.jpg';
import bad_4 from '../../resources/assets/project/rules/bad_4.jpg';
import bad_5 from '../../resources/assets/project/rules/bad_5.jpg';
import bad_6 from '../../resources/assets/project/rules/bad_6.jpg';
import bad_7 from '../../resources/assets/project/rules/bad_7.jpg';
import bad_8 from '../../resources/assets/project/rules/bad_8.jpg';
import good_1 from '../../resources/assets/project/rules/good_1.jpg';
import good_2 from '../../resources/assets/project/rules/good_2.jpg';
import good_3 from '../../resources/assets/project/rules/good_3.jpg';
import good_4 from '../../resources/assets/project/rules/good_4.jpg';
import good_5 from '../../resources/assets/project/rules/good_5.jpg';
import good_6 from '../../resources/assets/project/rules/good_6.jpg';
import good_7 from '../../resources/assets/project/rules/good_7.jpg';
import good_8 from '../../resources/assets/project/rules/good_8.jpg';

const PhotoItem = ({ img, text, textColor }) => (
  <VStack spacing='8px'>
    <Image
      src={img}
      width='100%'
      h='auto'
      aspectRatio={1}
      objectFit='cover'
    />
    {
      text && (
        <Text
          textStyle={TextStyle.bodyBold}
          color={textColor}
        >
          { text }
        </Text>
      )
    }
  </VStack>
);

const Gallery = ({ images, texts, textColor, title, titleTagsWithStyles, details }) => (
  <Flex
    direction='column'
    gap='20px'
    w='100%'
  >
    <VStack
      spacing='8px'
      alignSelf='flex-start'
      alignItems='flex-start'
    >
      <ChakraStyleTagText
        text={title}
        commonStyle={{ textStyle: TextStyle.h4Bold, color: Colors.black }}
        tagsWithStyles={titleTagsWithStyles}
      />
      <Text
        color={Colors.black}
        textStyle={TextStyle.body}
      >
        { details }
      </Text>
    </VStack>
    <SimpleGrid
      w='100%'
      columns={4}
      spacing='20px'
    >
      {
        images.map((image, index) => (
          <PhotoItem
            key={index}
            img={image}
            text={texts && index < texts.length && texts[index]}
            textColor={textColor}
          />
        ))
      }
    </SimpleGrid>
  </Flex>
);

const Rules = () => {
  const { t } = useTranslation();
  const [checkboxState, setCheckboxState] = useState(false);
  const dispatch = useDispatch();
  const editingProject = useSelector((state) => state.projects.editingProject);
  const navigate = useNavigate();

  const currentProjectStatus = editingProject.status;
  const completed = currentProjectStatus !== ProjectStatus.photoRules;

  return (
    <VStack
      w='100%'
      spacing='36px'
    >
      <Gallery
        images={[
          good_1,
          good_2,
          good_3,
          good_4,
          good_5,
          good_6,
          good_7,
          good_8,
        ]}
        title={t('project.rules.goodPhotos.title')}
        details={t('project.rules.goodPhotos.text')}
        textColor={Colors.green}
        titleTagsWithStyles={{c: { color: Colors.green }}}
      />
      <Gallery
        images={[
          bad_1,
          bad_2,
          bad_3,
          bad_4,
          bad_5,
          bad_6,
          bad_7,
          bad_8,
        ]}
        title={t('project.rules.badPhotos.title')}
        details={t('project.rules.badPhotos.text')}
        textColor={Colors.red}
        titleTagsWithStyles={{c: { color: Colors.red }}}
        texts={[
          t('project.rules.badPhotos.example_1'),
          t('project.rules.badPhotos.example_2'),
          t('project.rules.badPhotos.example_3'),
          t('project.rules.badPhotos.example_4'),
          t('project.rules.badPhotos.example_5'),
          t('project.rules.badPhotos.example_6'),
          t('project.rules.badPhotos.example_7'),
          t('project.rules.badPhotos.example_8')
        ]}
      />
      <VStack
        spacing='16px'
      >
        <Checkbox
          isDisabled={completed}
          defaultChecked={completed || checkboxState}
          colorScheme='blue'
          textStyle={TextStyle.body}
          onChange={(e) => setCheckboxState(e.target.checked)}
          borderColor={Colors.blue}
          w={{ md: '70%' }}
        >
          <ChakraStyleTagText
          text={ t('project.rules.confimrationTitle') }
          tagsWithStyles={{ l: { textDecoration: 'underline', cursor: 'pointer' }}}
          tagsActions={{ l: () => navigate(RouteIds.Terms) }}
          />
        </Checkbox>
        <FilledButton
          backgroundColor={Colors.blue}
          text={t('common.continue')}
          isDisabled={!completed && !checkboxState}
          onClick={() => dispatch(updateProject())}
          isLoading={editingProject.isUpdating}
        />
      </VStack>
    </VStack>
  );
};  

export default Rules;
import { Center, Grid, GridItem, Text, VStack } from '@chakra-ui/react';
import { Colors, TextStyle } from '../../theme';
import { useTranslation } from 'react-i18next';
import PriceTariff from '../../components/PriceTariff';
import { useNavigate } from 'react-router-dom';
import RouteIds from '../../helpers/RouteIds';
import { PackageType } from '../../models/PurchaseItems';
import { chakraMultilineText } from '../../helpers/chakraUtils';

const PricesSection = ({ propRef }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <VStack
      w='100%'
      spacing='72px'
    >
      <Text
        textAlign='center'
        color={Colors.black}
        textStyle={TextStyle.h2Bold}
        ref={(ref) => propRef.current = ref}
      >
        { chakraMultilineText(t('welcome.pricesSection.title')) }
      </Text>
      <Center
        maxW='1050px'
      >
        <Grid
          w='100%'
          columnGap={{ base: '8px', md: '16px', xl: '20px' }}
          rowGap='32px'
          templateColumns={{ base: 'repeat(4, 1fr)', md: 'repeat(12, 1fr)' }}
        >
          <GridItem
            colSpan={{ base: 4, md: 4 }}
          >
            <PriceTariff
              packageType={PackageType.lite}
              border='1px'
              borderColor='rgba(0,0,0,0.2)'
              onClick={() => navigate(RouteIds.Login, { replace: false })}
            />
          </GridItem>
          <GridItem
            colStart={{ base: 1, md: 5 }}
            colSpan={{ base: 4, md: 4 }}
          >
            <PriceTariff
              activeColor={Colors.blue}
              packageType={PackageType.normal}
              border='1px'
              borderColor='rgba(0,0,0,0.2)'
              onClick={() => navigate(RouteIds.Login, { replace: false })}
              badge={t('common.mostPopular')}
            />
          </GridItem>
          <GridItem
            colStart={{ base: 1, md: 9 }}
            colSpan={{ base: 4, md: 4 }}
          >
            <PriceTariff
              packageType={PackageType.pro}
              border='1px'
              borderColor='rgba(0,0,0,0.2)'
              onClick={() => navigate(RouteIds.Login, { replace: false })}
            />
          </GridItem>
        </Grid>
      </Center>
    </VStack>
  );
};

export default PricesSection;
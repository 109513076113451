import { Text, VStack, Image, SimpleGrid, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Colors, TextStyle } from '../../theme';
import img_1 from '../../resources/assets/landing/styles/suit_tie.jpg';
import img_2 from '../../resources/assets/landing/styles/smart_casual.jpg';
import img_3 from '../../resources/assets/landing/styles/hoodie.jpg';
import img_4 from '../../resources/assets/landing/styles/business.jpg';
import img_5 from '../../resources/assets/landing/styles/skies.jpg';
import img_6 from '../../resources/assets/landing/styles/nature.jpg';
import img_7 from '../../resources/assets/landing/styles/golden_hour.jpg';
import img_8 from '../../resources/assets/landing/styles/cafe.jpg';
import img_9 from '../../resources/assets/landing/styles/megapolis.jpg';
import img_10 from '../../resources/assets/landing/styles/mountains.jpg';
import { defaultScreenPadding } from '../../helpers/utils';

const Styles = () => {
  const { t } = useTranslation();

  const images = [
    img_1,
    img_2,
    img_3,
    img_4,
    img_5,
    img_6,
    img_7,
    img_8,
    img_9,
    img_10,
  ];

  return (
    <VStack
      w='100%'
      spacing='72px'
    >
      <Text textAlign='center' color={Colors.black} textStyle={TextStyle.h2Bold}>
        { t('welcome.styles.title') }
      </Text>
      <SimpleGrid
        w='100%'
        columns={{ base: 2, lg: 5 }}
        spacing={{ base: '16px', md: '24px' }}
      >
        { images.map((img, index) => (
          <Box
            position='relative'
            h='auto'
            aspectRatio={236/354}
            key={index}
            borderRadius='8px'
            overflow='hidden'
          >
            <Image
              src={img}
              w='100%'
              h='100%'
              objectFit='cover'
            />
            <Box
              position='absolute'
              top={0}
              left={0}
              right={0}
              p='24px'
              bgGradient='linear(to-b, rgba(15, 23, 42, 0.50), rgba(15, 23, 42, 0))'
            >
              <Text
                textStyle={TextStyle.h5}
                textColor={Colors.white}
              >
                { t(`welcome.styles.item_${index + 1}`) }
              </Text>
            </Box>
          </Box>
        )) }
      </SimpleGrid>
    </VStack>
  );
};

export default Styles;
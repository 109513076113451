import { Text, VStack, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Colors, TextStyle } from '../../theme';

const FAQ = ({ propRef }) => {
  const { t } = useTranslation();

  return (
    <VStack
      w='100%'
      spacing='72px'
      ref={(ref) => propRef.current = ref}
    >
      <Text textAlign='center' color={Colors.black} textStyle={TextStyle.h2Bold}>
        { t('welcome.faq.title') }
      </Text>
      <Accordion w='100%' allowMultiple>
        {
          [1,2,3,4,5].map((item) => (
            <AccordionItem key={item} borderColor={Colors.lightGray}>
              <AccordionButton>
                <Text
                  my='16px'
                  flex={1}
                  textStyle={TextStyle.h5}
                  color={Colors.black}
                  textAlign='left'
                >
                  { t(`welcome.faq.question_${item}.text`) }
                </Text>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Text
                  textStyle={TextStyle.body}
                  color={Colors.black}
                >
                  { t(`welcome.faq.question_${item}.answer`) }
                </Text>
              </AccordionPanel>
            </AccordionItem>
          ))
        }
      </Accordion>
    </VStack>
  );
};

export default FAQ;
import { Box, Button, Center, Flex, Grid, GridItem, HStack, Image, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import DefaultLayout from '../../components/DefaultLayout';
import { Colors, TextStyle } from '../../theme';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { makeSelectProjects } from '../../slices/projects.slice';
import { useSelector } from 'react-redux';

import Camera from '../../resources/assets/common/camera.svg';
import UserAvatar from '../../resources/assets/listProjects/userAvatar.svg';
import { ProjectStatus, colorOfProjectStatus, titleOfProjectStatus } from '../../models/ProjectStatus';
import TopBar from '../../components/TopBar';
import { useNavigate } from 'react-router-dom';
import RouteIds from '../../helpers/RouteIds';

const Gallery = ({ images, hasBlur }) => {
  if ((images || []).length === 0) {
    return (
      <Center
        w='100%'
        flexGrow={1}
        backgroundColor='rgba(217,217,217, 0.9)'
        borderTopLeftRadius='inherit'
        borderTopRightRadius='inherit'
      >
        <Image
          color={Colors.lightBlue}
          src={UserAvatar}
          w='25%'
          h='auto'
          aspectRatio={1}
        />
      </Center>
    );
  }
  return (
    <Flex
      flexDirection='column'
      flexGrow={1}
      w='100%'
      borderTopLeftRadius='inherit'
      borderTopRightRadius='inherit'
      overflow='hidden'
    >
      <SimpleGrid
        w='100%'
        flexGrow={1}
        backgroundColor='rgba(217,217,217, 0.9)'
        spacing={0}
        borderTopLeftRadius='inherit'
        borderTopRightRadius='inherit'
        overflow='hidden'
        columns={3}
        templateRows='repeat(2, 1fr)'
      >
        {
          images.slice(0, 6).map((image, index) => (
            <Box
              h='100%'
              w='100%'
              key={index}
            >
              <Image
                w='100%'
                h='100%'
                aspectRatio={1}
                objectFit='cover'
                src={image.url}
                style={{ filter: hasBlur && 'blur(5px)' }}
              />
            </Box>            
          ))
        }
      </SimpleGrid>
    </Flex>
  );
};

const titleOfButton = (item, t) => {
  if (item.status === ProjectStatus.completed) {
    return t('projectsCollection.openPhotos.format', { count: (item.generatedPhotos || []).length })
  }
  return t('projectsCollection.continueEditing');
};

const CardItem = ({ item, onClick }) => {
  const { t } = useTranslation();

  return (
    <VStack
      w='100%'
      aspectRatio={1}
      h='auto'
      backgroundColor={Colors.white}
      spacing={0}
      borderRadius='16px'
      onClick={onClick}
      cursor='pointer'
    >
      <Gallery
        images={((item.generatedPhotos && item.generatedPhotos.length > 0 && item.generatedPhotos) || item.userPhotos)}
        hasBlur={item.status === ProjectStatus.processing}
      />
      <VStack
        p='12px'
        spacing='16px'
        w='100%'
      >
        <HStack
          justifyContent='space-between'
          w='100%'
        >
          <Text
            textStyle={TextStyle.body}
            color={Colors.black}
            flexShrink={2}
          >
            { item.name }
          </Text>
          <Box
            backgroundColor={colorOfProjectStatus(item.status)}
            px='12px'
            py='4px'
            borderRadius='0.9375rem'
            flexShrink={0}
          >
            <Text
              textStyle={TextStyle.footnoteBold}
              color={Colors.white}
              noOfLines={1}
            >
              { titleOfProjectStatus(item.status) }
            </Text>
          </Box>
        </HStack>
        <Text
          textStyle={TextStyle.body}
          color={Colors.blue}
          h='auto'
        >
          { titleOfButton(item, t) }
        </Text>
      </VStack>
    </VStack>
  );
}

const CreateNewItem = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Flex
      w='100%'
      aspectRatio={1}
      h='auto'
      backgroundColor={Colors.white}
      justifyContent='center'
      alignItems='center'
      gap='20px'
      borderRadius='16px'
      onClick={onClick}
      flexDirection='column'
      cursor='pointer'
    >
      <Image
        w='45%'
        h='45%'
        src={Camera}
      />
      <Text
        textStyle={TextStyle.h5}
        color={Colors.black}
      >
        { t('listProjects.createNew') }
      </Text>
    </Flex>
  )
};

const ProjectsCollection = () => {
  const selectItemsByIds = useMemo(makeSelectProjects, []);

  const itemIds = useSelector((state) => state.projects.list);

  const items = useSelector((state) => selectItemsByIds(state, itemIds));
  const navigate = useNavigate();

  return (
    <DefaultLayout
      backgroundColor={Colors.lightBlue}
      topBar={<TopBar backgroundColor={Colors.white} />}
      hasFooter
      footerColor={Colors.white}
    >
      <Flex
        w='100%'
        flexGrow={1}
        direction='column'
      >
        <Grid
          w='100%'
          columnGap={{ base: '16px', xl: '20px' }}
          rowGap={{ base: '16px', xl: '20px' }}
          templateRows='1fr'
          templateColumns={{ base: 'repeat(4, 1fr)', sm: 'repeat(8, 1fr)', lg: 'repeat(12, 1fr)' }}
        >
          <GridItem
            colSpan={{ base: 4, lg: 3 }}
          >
            <CreateNewItem
              onClick={() => navigate(RouteIds.NewProject, { replace: false })}
            />
          </GridItem>
          { items.map((item) => (
            <GridItem
              key={item.id}
              colSpan={{ base: 4, lg: 3 }}
            >
              <CardItem
                item={item}
                onClick={() => {
                  if (item.status === ProjectStatus.completed) {
                    navigate(RouteIds.CompleteProjectWithId(item.id), { replace: false });
                  } else {
                    navigate(RouteIds.ProjectWithId(item.id), { replace: false });
                  }
                }}
              />
            </GridItem>
          )) }
        </Grid>
      </Flex>
    </DefaultLayout>
  )
};

export default ProjectsCollection;
import { Center, Grid, GridItem } from '@chakra-ui/react';
import ReactGA from 'react-ga4';

import PriceTariff from '../../components/PriceTariff';
import { Colors } from '../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { loadAfterPay } from '../../slices/projects.slice';
import { getPurchaseItemId, PackageType } from '../../models/PurchaseItems';
import { useTranslation } from 'react-i18next';

const Pay = ({ paddle }) => {
  const { t } = useTranslation();
  const editingProject = useSelector((state) => state.projects.editingProject);
  const user = useSelector((state) => state.users.mainUser);
  const openCheckout = (packageType) => { 
    const priceId = getPurchaseItemId(user.isPurchaseSandbox, packageType);
    ReactGA.event('begin_checkout');
    paddle && paddle.Checkout.open({
      settings: {
        theme: "light"
      },
      items: [
        {
          priceId,
          quantity: 1
        }
      ],
      customData: {
        projectId: editingProject.id,
        userId: user.id
      },
      customer: {
        email: user.email
      },
      discountCode: editingProject.hasSale ? 'PROFILEPHOTO' : null
    });
  };

  return (
    <Center w='100%'>
      <Center
        maxW='1050px'
      >
        <Grid
          w='100%'
          columnGap={{ base: '8px', md: '16px', xl: '20px' }}
          rowGap='32px'
          templateColumns={{ base: 'repeat(4, 1fr)', md: 'repeat(12, 1fr)' }}
        >
          <GridItem
            colSpan={{ base: 4, md: 4 }}
          >
            <PriceTariff
              packageType={PackageType.lite}
              border='1px'
              borderColor='rgba(0,0,0,0.2)'
              isSandbox={user.isPurchaseSandbox}
              backgroundColor={Colors.white}
              onClick={() => openCheckout(PackageType.lite)}
              hasSale={editingProject.hasSale}
            />
          </GridItem>
          <GridItem
            colStart={{ base: 1, md: 5 }}
            colSpan={{ base: 4, md: 4 }}
          >
            <PriceTariff
              activeColor={Colors.blue}
              packageType={PackageType.normal}
              border='1px'
              borderColor='rgba(0,0,0,0.2)'
              isSandbox={user.isPurchaseSandbox}
              backgroundColor={Colors.white}
              onClick={() => openCheckout(PackageType.normal)}
              badge={t('common.mostPopular')}
              hasSale={editingProject.hasSale}
            />
          </GridItem>
          <GridItem
            colStart={{ base: 1, md: 9 }}
            colSpan={{ base: 4, md: 4 }}
          >
            <PriceTariff
              packageType={PackageType.pro}
              border='1px'
              borderColor='rgba(0,0,0,0.2)'
              isSandbox={user.isPurchaseSandbox}
              backgroundColor={Colors.white}
              onClick={() => openCheckout(PackageType.pro)}
              hasSale={editingProject.hasSale}
            />
          </GridItem>
        </Grid>
      </Center>
    </Center>
  );
};

export default Pay;
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */

import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/auth.slice';
import projectsReducer from '../slices/projects.slice';
import usersReducer from '../slices/user.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    projects: projectsReducer,
    users: usersReducer
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

import { AbsoluteCenter, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Center, CircularProgress, Flex, Grid, GridItem, HStack, Icon, IconButton, Image, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, SimpleGrid, Spacer, Spinner, Text, VStack, useDisclosure } from '@chakra-ui/react';
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Colors, TextStyle } from '../../theme';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addNewPhotos, removePhoto, updateProject, uploadPhoto, uploadPhotos, MINIMUM_COUNT_PHOTOS } from '../../slices/projects.slice';
import FilledButton from '../../components/FilledButton';
import Cross from '../../resources/assets/common/cross.svg';
import { IoReloadOutline } from 'react-icons/io5';
import { BiErrorCircle } from 'react-icons/bi';

const EmptyPhotos = () => {
  const { t } = useTranslation();

  return (
    <Flex
      border='dashed 1px'
      borderColor='rgba(80,80,80, 0.6)'
      borderRadius='16px'
      position='relative'
      justifyContent='center'
      alignItems='center'
      flexGrow={1}
    >
      <Text
        textStyle={TextStyle.h5}
        color={Colors.black}
      >
        { t('project.uploadPhoto.dropZone.title') }
      </Text>
      <Box
        position='absolute'
        bottom='16px'
        left={0}
        right={0}
      >
        <Center>
          <Text
            textStyle={TextStyle.body}
            color={Colors.lightGray}
          >
            { t('project.uploadPhoto.dropZone.caption') }
          </Text>
        </Center>
      </Box>
    </Flex>
  )
};

const PhotoItem = ({ photo, onDelete, onReload }) => (
  <Box
    h='auto'
    aspectRatio={1}
    w='100%'
    borderRadius='8px'
    position='relative'
  >
    <Image
      h='100%'
      w='100%'
      objectFit='cover'
      src={photo.url || photo.preview}
      borderRadius='inherit'
    />
    {
      photo.isUploading && (
        <Center
          position='absolute'
          top={0}
          bottom={0}
          left={0}
          right={0}
          backgroundColor='rgba(0,0,0, 0.6)'
          borderRadius='8px'
        >
          <CircularProgress
            thickness='2px'
            color={Colors.white}
            value={photo.progress * 100}
            trackColor='transparent'
            size='40px'
          />
        </Center>
      )
    }
    {
      photo.error && (
        <>
          <Center
            position='absolute'
            top={0}
            bottom={0}
            left={0}
            right={0}
            backgroundColor='rgba(0,0,0, 0.6)'
            borderRadius='8px'
            cursor='pointer'
            onClick={onReload}
          >
            <Icon
              as={IoReloadOutline}
              color={Colors.white}
              w='32px'
              h='32px'
            />
          </Center>
          <Icon
            position='absolute'
            right='4px'
            bottom='4px'
            as={BiErrorCircle}
            color={Colors.white}
          />
        </>        
      )
    }
    <Image
      position='absolute'
      top='-12px'
      right='-12px'
      w='24px'
      h='24px'
      src={Cross}
      onClick={onDelete}
    />
  </Box>
);

const FilledPhotos = ({ uploadVisible, photos, addMoreClick, uploadClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const uploadedCount = photos.filter((item) => item.url).length;

  return (
    <Flex
      flexGrow={1}
      flexDirection='column'
      w='100%'
      gap='16px'
    >
      <Box
        border='dashed 1px'
        borderColor='rgba(80,80,80, 0.6)'
        borderRadius='16px'
        flexGrow={1}
        p='16px'
      >
        <SimpleGrid
          w='100%'
          columns={4}
          spacing='32px'
        >
          {
            photos.map(item => (
              <PhotoItem
                photo={item}
                key={item.url || item.localId}
                onDelete={() => dispatch(removePhoto(item))}
                onReload={() => dispatch(uploadPhoto(item))}
              />
            ))
          }
        </SimpleGrid>
      </Box>
      <HStack
        justifyContent='space-between'
        w='100%'
      >
        {
          uploadVisible
          ? (
            <FilledButton
              backgroundColor={Colors.green}
              text={t('project.uploadPhoto.uploadPhotos')}
              onClick={uploadClick}
            />
          )
          : (<Box />)
        }
        <AbsoluteCenter axis='horizontal'>
          <Text
            color={Colors.black}
            textStyle={TextStyle.body}
          >
            { t('project.uploadPhoto.uploadFormat', { uploadedCount, allCount: photos.length }) }
          </Text>
        </AbsoluteCenter>
        <Button
          textStyle={TextStyle.body}
          textColor={Colors.blue}
          variant='ghost'
          onClick={addMoreClick}
          px={0}
          _hover={{ bg: 'transparent' }}
          py={0}
        >
          { t('project.uploadPhoto.addPhotos') }
        </Button>
      </HStack>
    </Flex>
  )
};

const MainLayout = ({ children, isPhotoConverting }) => {
  const { t } = useTranslation();

  return (
    <Grid
      columnGap={{ base: '8px', md: '16px', xl: '20px' }}
      templateColumns={{ base: 'repeat(4, 1fr)', md: 'repeat(8, 1fr)', lg: 'repeat(12, 1fr)' }}
      w='100%'
      flexGrow={1}
      templateRows='1fr'
    >
      <GridItem
        colSpan={{ base: 4, md: 6, lg: 6 }}
        colStart={{ base: 1, md: 2, lg: 4 }}
        h='100%'
      >
        <Flex
          position='relative'
          p='16px'
          w='100%'
          minH='100%'
          borderRadius='16px'
          backgroundColor={Colors.white}
        >
          { children }
          {
            isPhotoConverting && (
              <VStack
                position='absolute'
                top={0}
                left={0}
                right={0}
                bottom={0}
                backgroundColor='rgba(255,255,255,0.8)'
                spacing='16px'
                justify='center'
                borderRadius='16px'
              >
                <Spinner size='lg' color={Colors.black} />
                <Text
                  color={Colors.black}
                  textStyle={TextStyle.footnote}
                >
                  { t('project.convertPhoto') }
                </Text>
              </VStack>
            )
          }
        </Flex>
      </GridItem>
    </Grid>
  );
};

const UploadPhoto = ({ isSubmitForTrain }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onDrop = useCallback(acceptedFiles => {
    dispatch(addNewPhotos(acceptedFiles));
  }, [dispatch]);
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.jpeg', '.png', '.bmp', '.jpg', '.heic'] },
    maxFiles: 30,
    multiple: true,
  });
  const editingProject = useSelector((state) => state.projects.editingProject);
  const photos = editingProject.userPhotos;
  const allLocal = photos.every((item) => !item.isUploading && !item.url);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const uploadedCount = photos.filter((item) => item.url).length;

  const onSubmit = () => {
    onClose();
    dispatch(updateProject());
  };

  const onClickSubmit = () => {
    if (isSubmitForTrain) {
      onOpen();
    } else {
      onSubmit();
    }
  };

  return (
    <VStack
      flexGrow={1}
      w='100%'
      spacing='48px'
    >
      <MainLayout isPhotoConverting={editingProject.isPhotoConverting}>
        {
          photos.length === 0
          ? (
            <EmptyPhotos />
          )
          : (
            <FilledPhotos
              photos={photos}
              uploadVisible={allLocal}
              addMoreClick={open}
              uploadClick={() => dispatch(uploadPhotos())}
            />
          )
        }
        <Box
          {...getRootProps()}
          hidden={photos.length > 0}
          position='absolute'
          top={0}
          bottom={0}
          left={0}
          right={0}
        >
          <input {...getInputProps()} />
        </Box>
      </MainLayout>
      <VStack
        spacing='16px'
      >
        {
          (uploadedCount < MINIMUM_COUNT_PHOTOS) && (
            <VStack
              spacing='8px'
            >
              <Text
                textStyle={TextStyle.footnote}
                color={Colors.black}
              >
                { t('project.uploadPhoto.minimumPhotosFormat', { count: MINIMUM_COUNT_PHOTOS }) }
              </Text>
              <Popover placement='top'>
                <PopoverTrigger>
                  <Text
                    textColor={Colors.blue}
                    variant='ghost'
                    px={0}
                    _hover={{ bg: 'transparent' }}
                    py={0}
                    textStyle={TextStyle.footnoteBold}
                    cursor='pointer'
                  >
                    { t('project.uploadPhoto.dontHavePhotos.title', { count: MINIMUM_COUNT_PHOTOS }) }
                  </Text>
                </PopoverTrigger>
                <PopoverContent borderColor='transparent'>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>{t('project.uploadPhoto.dontHavePhotos.body', { count: MINIMUM_COUNT_PHOTOS })}</PopoverBody>
                </PopoverContent>
              </Popover>
            </VStack>
          )
        }
        <FilledButton
          text={ isSubmitForTrain ? t('project.finish.button') : t('common.continue')}
          isDisabled={photos.length === 0 || editingProject.hasUnloadedPhotos}
          backgroundColor={Colors.blue}
          isLoading={editingProject.isUpdating}
          onClick={onClickSubmit}
        />
      </VStack>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              { t('project.finish.alert.title') }
            </AlertDialogHeader>
            <AlertDialogBody>
            { t('project.finish.alert.text') }
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} textColor={Colors.white} backgroundColor={Colors.lightGray}>
                { t('common.cancel') }
              </Button>
              <Button backgroundColor={Colors.blue} textColor={Colors.white} onClick={onSubmit} ml={3}>
                { t('common.submit') }
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  );
};

export default UploadPhoto;
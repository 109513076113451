export const PackageType = {
  normal: 'normal',
  lite: 'lite',
  pro: 'pro'
};

const SANDBOX_NORMAL_ID = 'pri_01haseeahycmzbzcwgkcpaf376';
const NORMAL_ID = 'pri_01hcyhgx29aham7d21gv44s4yz';
const SANDBOX_LITE_ID = 'pri_01hf4wp68k34j4je4qx1eth6av';
const LITE_ID = 'pri_01hf6aw9wgvkw799ytcdmgkchb';
const SANDBOX_PRO_ID = 'pri_01hf6b43texdrx6rdv26xzge39';
const PRO_ID = 'pri_01hf6b5betk2p18cb1n108hkv2';

export const getPurchaseItemId = (isSandbox, packageType) => {
  switch (packageType) {
    case PackageType.pro:
      if (isSandbox) {
        return SANDBOX_PRO_ID;
      }
      return PRO_ID;
    case PackageType.lite:
      if (isSandbox) {
        return SANDBOX_LITE_ID;
      }
      return LITE_ID;
    case PackageType.normal:
      if (isSandbox) {
        return SANDBOX_NORMAL_ID;
      }
      return NORMAL_ID;
    default:
      if (isSandbox) {
        return SANDBOX_NORMAL_ID;
      }
      return NORMAL_ID;
  }
};
import React from 'react';
import { Text } from '@chakra-ui/react';
import { chakraMultilineText } from '../helpers/chakraUtils';

const tagSubstrings = (str) => {
  const answer = []; let x;
  for (let i = 0, l = str.length; i < l; i += 1) {
    x = i;
    if (str[i] === '<') {
      while (str[++i] !== '>');
      answer.push(str.substring(x, i + 1));
    } else {
      while (++i < l && str[i] !== '<');
      answer.push(str.substring(x, i));
      i -= 1;
    }
  }
  return answer;
};

const ChakraStyleTagText = ({
  text, commonStyle, tagsWithStyles, tagsActions,
}) => {
  const keys = Object.keys(tagsWithStyles);
  const strings = tagSubstrings(text);
  const results = [];
  let currentTags = [];
  let currentString = '';
  for (let i = 0; i < strings.length; i += 1) {
    if (keys.find((key) => strings[i] === `<${key}>`)) {
      const copyTags = JSON.parse(JSON.stringify(currentTags));
      results.push({
        text: currentString,
        style: currentTags.reduce((obj, tag) => Object.assign(obj, tagsWithStyles[tag]), {}),
        onClick: () => copyTags.forEach((tag) => tagsActions[tag] && tagsActions[tag]()),
      });
      currentString = '';
      currentTags.push(keys.find((key) => strings[i] === `<${key}>`));
    } else if (keys.find((key) => strings[i] === `</${key}>`)) {
      const copyTags = JSON.parse(JSON.stringify(currentTags));
      results.push({
        text: currentString,
        style: currentTags.reduce((obj, tag) => Object.assign(obj, tagsWithStyles[tag]), {}),
        onClick: () => copyTags.forEach((tag) => tagsActions[tag] && tagsActions[tag]()),
      });
      currentString = '';
      currentTags = currentTags.filter((item) => item !== keys.find((key) => strings[i] === `</${key}>`));
    } else {
      currentString += strings[i];
    }
  }
  results.push({ text: currentString, style: {} });
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Text sx={{...commonStyle}}>
      {
        results.map(({ text: resultText, style, onClick }) => {
          if (Object.keys(style).length > 0) {
            return (
              <Text
                key={resultText}
                sx={{ ...style }}
                onClick={() => onClick()}
                as='span'
              >
                {chakraMultilineText(resultText)}
              </Text>
            );
          }
          return (<React.Fragment key={resultText}>{chakraMultilineText(resultText)}</React.Fragment>);
        })
      }
    </Text>
  );
};

export default React.memo(ChakraStyleTagText);

import { Flex } from '@chakra-ui/react';
import { defaultScreenPadding, maxWidth } from '../helpers/utils';
import { Colors } from '../theme';
import Footer from './Footer';

const DefaultLayout = ({ children, topBar, hasFooter, backgroundColor, footerColor, overflowX }) => (
  <Flex
    backgroundColor={backgroundColor || Colors.white}
    minH='100vh'
    direction='column'
  >
    { topBar }
    <Flex
      grow={1}
      maxW={maxWidth}
      px={defaultScreenPadding}
      direction='column'
      alignSelf='center'
      w='100%'
    >
      <Flex
        w='100%'
        py={4}
        direction='column'
        grow={1}
        overflowX={overflowX || 'hidden'}
      >
        { children }
      </Flex>
    </Flex>
    {
      hasFooter && (
        <Footer backgroundColor={footerColor} />
      )
    }
  </Flex>
);

export default DefaultLayout;
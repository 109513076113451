export const asyncTimeout = async (delay) => new Promise((resolve) => {
  setTimeout(resolve, delay);
});

export const asyncTimeInterval = async (delay, number, callback) => new Promise((resolve) => {
  const interval = setInterval(() => {
    callback();
  }, delay);
  setTimeout(() => {
    resolve();
    clearInterval(interval);
  }, delay * number + 20);
});

export const defaultScreenPadding = { base: '16px', md: '20px', xl: '60px' };
export const maxWidth = '1440px';
export const minusDefaultScreenPadding = { base: '-16px', md: '-20px', xl: '-60px' };

export const isMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return true;
  }

  if (/android/i.test(userAgent)) {
    return true;
  }

  if (is_iOS()) {
    return true;
  }

  return false;
};

export const is_iOS = () => {
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
};

export const isWebview = () => {
  const navigator = window.navigator
  const userAgent = navigator.userAgent
  const normalizedUserAgent = userAgent.toLowerCase()
  const standalone = navigator.standalone

  const isIos =
    /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  const isAndroid = /android/.test(normalizedUserAgent)
  const isSafari = /safari/.test(normalizedUserAgent)
  const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari)

  return isWebview
};

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

import { Button } from '@chakra-ui/react';
import { Colors, TextStyle } from '../theme';

const FilledButton = ({ onClick, text, isLoading, backgroundColor, textColor, isDisabled, width }) => (
  <Button
    textStyle={TextStyle.bodyBold}
    w={width || 'auto'}
    h='auto'
    px={4}
    py={2}
    backgroundColor={backgroundColor || Colors.black}
    textColor={textColor || Colors.white}
    variant='solid'
    onClick={onClick}
    isLoading={isLoading}
    borderRadius='1.125rem'
    isDisabled={isDisabled}
    _hover={{ backgroundColor, opacity: 0.6 }}
  >
    { text }
  </Button>
);

export default FilledButton;
import { Show, useBreakpoint } from '@chakra-ui/react';

const WebAndMobileLayout = ({ breakpoint, web, mobile }) => {
  const currentBreakpoint = useBreakpoint();

  if (currentBreakpoint === breakpoint) {
    return web;
  }

  return (
    <>
      <Show above={breakpoint}>
        { web }
      </Show>
      <Show below={breakpoint}>
        { mobile }
      </Show>
    </>
  );
};

export default WebAndMobileLayout;
import React from 'react';
import _ from 'lodash';

import { Box, Button, Center, Flex, Grid, GridItem, HStack, Hide, Icon, Image, Spinner, Text, VStack } from '@chakra-ui/react';
import DefaultLayout from '../../components/DefaultLayout';
import { Colors, TextStyle } from '../../theme';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { clearErrorAfterPayment, closeProjectScreen, loadAfterPay, setActiveProject, setVisibleStatus } from '../../slices/projects.slice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineForm, AiOutlineInfo } from 'react-icons/ai';
import { BsCamera } from 'react-icons/bs';
import { VscCreditCard } from 'react-icons/vsc';
import { initializePaddle } from '@paddle/paddle-js';

import TopBar from '../../components/TopBar';

import { ProjectStatus } from '../../models/ProjectStatus';
import UploadPhoto from './UploadPhoto';
import Rules from './Rules';
import Pay from './Pay';
import ProjectGeneral from './ProjectGeneral';
import { useNavigate, useParams } from 'react-router-dom';
import { chakraMultilineText } from '../../helpers/chakraUtils';
import FilledButton from '../../components/FilledButton';
import Constants from '../../config/constants';
import RouteIds from '../../helpers/RouteIds';

const ItemType = {
  image: 'image',
  text: 'text'
};

const ItemStatus = {
  completed: 'completed',
  active: 'active',
  notStarted: 'notStarted'
};

const getColorStatus = (status) => {
  switch (status) {
    case ItemStatus.completed:
    case ItemStatus.active:
      return Colors.blue;
    default:
      return Colors.lightGray;
  }
};

const ProgressBarItemContent = ({ itemType, image, color }) => (
  <Center
    w='24px'
    h='24px'
    borderRadius='12px'
    backgroundColor={color}
  >
    {
      itemType === ItemType.image
      ? image
      : (
        <Text
          textStyle={TextStyle.body}
          color={Colors.white}
        >
          { image }
        </Text>
      )
    }
  </Center>
);

const ProgressBarItem = ({ itemType, image, text, status, onClick }) => (
  <HStack spacing='8px' onClick={onClick} cursor='pointer'>
    {
      (status !== ItemStatus.active)
      ? (
        <ProgressBarItemContent
          itemType={itemType}
          image={image}
          color={getColorStatus(status)}
        />
      )
      : (
        <Center
          w='32px'
          h='32px'
          border='1px'
          borderColor={getColorStatus(status)}
          borderRadius='16px'
        >
          <ProgressBarItemContent
            itemType={itemType}
            image={image}
            color={getColorStatus(status)}
          />
        </Center>
      )
    }
    <Hide below='md'>
      <Text textStyle={TextStyle.body} color={Colors.black}>
        { text }
      </Text>
    </Hide>
  </HStack>
);

const ProgressBar = ({ items, onSelect }) => (
  <Flex
    w='100%'
    direction='row'
    gap='4px'
    alignItems='center'
  >
    {
      items.map((item, index) => (
        <React.Fragment key={index}>
          {
            (index > 0) && (
              <Box
                h='1px'
                backgroundColor={getColorStatus(item.status)}
                flexGrow={1}
              />
            )
          }
          <ProgressBarItem
            onClick={() => onSelect && onSelect(index)}
            { ...item }
          />
        </React.Fragment>
      ))
    }
  </Flex>
);

const CommonContainer = ({ children }) => (
  <DefaultLayout
    backgroundColor={Colors.lightBlue}
    topBar={<TopBar logoHref='/app' backgroundColor={Colors.white} />}
    hasFooter
    footerColor={Colors.white}
  >
    { children }
  </DefaultLayout>
);

const MainContainer = ({ children, progressItems, currentTitle, onSelectItem }) => (
  <CommonContainer>
    <Flex
      w='100%'
      flexGrow={1}
      direction='column'
      pt='52px'
    >
      <VStack
        w='100%'
        spacing='32px'
      >
        <Grid
          w='100%'
          columnGap={{ base: '8px', md: '16px', xl: '20px' }}
          templateColumns={{ base: 'repeat(4, 1fr)', lg: 'repeat(12, 1fr)' }}
        >
          <GridItem
            colSpan={{ base: 4, lg: 10 }}
            colStart={{ base: 1, lg: 2 }}
          >
            <ProgressBar
              onSelect={onSelectItem}
              items={progressItems}
            />
          </GridItem>
        </Grid>
        <Text
          textStyle={TextStyle.h5Bold}
          color={Colors.black}
        >
          { currentTitle }
        </Text>
      </VStack>
      <Flex
        direction='column'
        flexGrow={1}
        my='48px'
        w='100%'
      >
        { children }
      </Flex>
    </Flex>
  </CommonContainer>
);

const CommonContainerWithCenter = ({ children }) => (
  <CommonContainer>
     <Flex
      w='100%'
      grow={1}
      justify='center'
      align='center'
    >
      { children }
    </Flex>
  </CommonContainer>
)

const LoadingScreen = ({ text }) => (
  <CommonContainerWithCenter>
    <VStack spacing='16px'>
      <Text
        textStyle={TextStyle.body}
        color={Colors.black}
        textAlign='center'
      >
        { text }
      </Text>
      <Spinner color={Colors.black} size='lg' />
    </VStack>
  </CommonContainerWithCenter>
);

const ErrorAfterPaymentScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <CommonContainerWithCenter>
      <VStack spacing='16px'>
        <Text
          textStyle={TextStyle.h5}
          color={Colors.black}
        >
          { t('project.error.title') }
        </Text>
        <Text
          textStyle={TextStyle.body}
          color={Colors.black}
        >
          { t('project.error.description') }
        </Text>
        <FilledButton
          text={t('common.contactUs')}
          onClick={() => {
            window.open('mailto:profilephotohelp@gmail.com');
          }}
        />
        <Button
          mt='8px'
          variant='link'
          color={Colors.lightGray}
          
          onClick={() => dispatch(clearErrorAfterPayment())}
        >
          { t('project.error.backToProject') }
        </Button>
      </VStack>
    </CommonContainerWithCenter>
  )
};  

const ProcessingScreen = () => {
  const { t } = useTranslation();

  return (
    <CommonContainerWithCenter>
      <Text
        textStyle={TextStyle.h5}
        color={Colors.black}
        textAlign='center'
      >
        { chakraMultilineText(t('project.processing.text')) }
      </Text>
    </CommonContainerWithCenter>
  )
};  

const statusToIndex = (status) => {
  switch (status) {
    case ProjectStatus.waitingForPhotos:
      return 2;
    case ProjectStatus.photoRules:
      return 1;
    case ProjectStatus.waitingForPay:
      return 3;
    default:
      return 0;
  }
};

const getProgressItems = (projectStatus, visibleStatus, t) => {
  const imageProps = {
    color: Colors.white,
    w: '12px',
    h: '12px'
  }
  const items = [
    { itemType: ItemType.image, image: <Icon { ...imageProps } as={AiOutlineForm} />, text: t('project.projectDetails.title') },
    { itemType: ItemType.image, image: <Icon { ...imageProps } as={AiOutlineInfo} />, text: t('project.rules.title') },
    { itemType: ItemType.image, image: <Icon { ...imageProps } as={BsCamera} />, text: t('project.uploadPhoto.title') },
    { itemType: ItemType.image, image: <Icon { ...imageProps } as={VscCreditCard} />, text: t('project.card.title') },
  ];

  let indexActive = statusToIndex(visibleStatus);
  let indexPassed = statusToIndex(projectStatus);

  items.forEach((item, index) => {
    items[index].status = ItemStatus.notStarted;
    if (index === indexActive) {
      items[index].status = ItemStatus.active;
    } else if (index <= indexPassed) {
      items[index].status = ItemStatus.completed;
    }    
  })

  return items;
};

const Project = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const editingProject = useSelector((state) => state.projects.editingProject);
  const user = useSelector((state) => state.users.mainUser);

  const currentStatus = editingProject.status;
  const visibleStatus = editingProject.visibleStatus;

  const navigate = useNavigate();

  const { projectId } = useParams();

  const [progressItems, setProgressItems] = useState(getProgressItems(currentStatus, visibleStatus, t));
  const [paddle, setPaddle] = useState(null);
  
  const eventCallback = (data) => {
    if (data.name !== 'checkout.completed') {
      return;
    }
    dispatch(loadAfterPay());
  };

  useEffect(() => {
    initializePaddle({
      environment: user.isPurchaseSandbox ? 'sandbox' : 'production',
      seller: user.isPurchaseSandbox ? 14473 : 177479,
      eventCallback: eventCallback
    }).then(
      (paddleInstance) => paddleInstance && setPaddle(paddleInstance)
    );
  }, []);

  const onSelectItem = (index) => {
    if (editingProject.hasUnloadedPhotos || editingProject.isUpdating) {
      return;
    }
    if (index > statusToIndex(currentStatus)) {
      return;
    }
    let newStatus = ProjectStatus.projectDetails;
    switch (index) {
      case 1:
        newStatus = ProjectStatus.photoRules;
        break;
      case 2:
        newStatus = ProjectStatus.waitingForPhotos;
        break;
      case 3:
        newStatus = ProjectStatus.waitingForPay;
        break;
      default:
        break;
    }
    if (visibleStatus !== newStatus) {
      dispatch(setVisibleStatus(newStatus));
    }
  };

  useEffect(() => {
    setProgressItems(getProgressItems(currentStatus, visibleStatus, t));
  }, [currentStatus, t, visibleStatus]);

  useEffect(() => () => {
    dispatch(closeProjectScreen());
  }, []);

  useEffect(() => {
    if (editingProject.visibleId === projectId || !dispatch) {
      return;
    }
    dispatch(setActiveProject(projectId));
  }, [projectId, dispatch, editingProject]);

  useEffect(() => {
    if (projectId === Constants.NEW_PROJECT && !_.isUndefined(editingProject.id)) {
      navigate(RouteIds.ProjectWithId(editingProject.id), { replace: true });
    }
  }, [projectId, navigate, editingProject]);

  if (editingProject.isLoading) {
    return (<LoadingScreen text={t('project.loading')} />);
  }

  if (editingProject.isLoadingAfterPay) {
    return (<LoadingScreen text={t('project.afterPayment.loading')} />);
  }

  if (editingProject.isErrorAfterLoadingAfterPay) {
    return (<ErrorAfterPaymentScreen />);
  }

  if (currentStatus === ProjectStatus.processing) {
    return (<ProcessingScreen />);
  }

  let innerComponent = null;

  switch (visibleStatus) {
    case ProjectStatus.waitingForPhotos:
      innerComponent = <UploadPhoto />;
      break;
    case ProjectStatus.photoRules:
      innerComponent = <Rules />;
      break;
    case ProjectStatus.waitingForPay:
      innerComponent = <Pay paddle={paddle} />;
      break;
    case ProjectStatus.projectDetails:
      innerComponent = <ProjectGeneral />;
      break;
    default:
      innerComponent = <ProjectGeneral />;
      break;
  }

  const selectedItem = progressItems.find((item) => item.status === ItemStatus.active);

  return (
    <MainContainer
      progressItems={progressItems}
      currentTitle={selectedItem && selectedItem.text}
      onSelectItem={onSelectItem}
    >
      { innerComponent }
    </MainContainer>
  );
};

export default Project;
import { Flex, HStack, Link, Text } from '@chakra-ui/react';
import { Colors, TextStyle } from '../theme';
import { defaultScreenPadding, maxWidth } from '../helpers/utils';
import { useTranslation } from 'react-i18next';
import RouteIds from '../helpers/RouteIds';

const Footer = ({ backgroundColor }) => {
  const { t } = useTranslation();

  return (
    <Flex backgroundColor={backgroundColor} w='100%' direction='column'>
      <Flex
        px={defaultScreenPadding}
        maxW={maxWidth}
        pt='20px'
        pb='60px'
        justifyContent='space-between'
        direction='row'
        alignSelf='center'
        w='100%'
      >
        <Text textStyle={TextStyle.body} color={Colors.lightGray}>
          Profile Photo
        </Text>
        <HStack spacing={{ base: '8px', md: '32px' }}>
          <Link href={RouteIds.Terms}>
            <Text textStyle={TextStyle.body} color={Colors.lightGray}>
              { t('footer.termsOfUse') }
            </Text>
          </Link>
          <Link href={RouteIds.Privacy}>
            <Text textStyle={TextStyle.body} color={Colors.lightGray}>
              { t('footer.privacy') }
            </Text>
          </Link>
        </HStack>
      </Flex>
    </Flex>
  )
}

export default Footer;
import { Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';
import Logo from '../../components/Logo';
import { Colors, TextStyle } from '../../theme';
import DefaultLayout from '../../components/DefaultLayout';
import RouteIds from '../../helpers/RouteIds';

const TopBar = () => (
  <Flex
    direction='row-reverse'
    as='nav'
    px={4}
    py={3}
    alignItems='center'
    justify='center'
  >
    <Logo />
  </Flex>
);

export default function Terms() {
  return (
    <DefaultLayout
      backgroundColor={Colors.white}
      topBar={<TopBar backgroundColor={Colors.white} />}
      hasFooter
      footerColor={Colors.white}
    >
      <Flex
        w='100%'
        flexGrow={1}
        direction='column'
      >
        <Text textStyle={TextStyle.h1} color={Colors.black} mb={4}>
          Terms of Service
        </Text>
        <Text textStyle={TextStyle.footnote} color={Colors.black} mb={4}>
          Last updated: September 23, 2023
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
          PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY, AS IT CONTAINS IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS AND REMEDIES.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          1. OVERVIEW
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        This Terms of Service Agreement ("Agreement") is entered into by and between Individual entrepreneur Iurii Moshkov, registered in Tbilisi, Georgia (doing business as “Profile Photo”, also referred to as “Company”) and you, and is made effective as of the date of your use of this website https://profilephoto.app ("Site") or the date of electronic acceptance.
This Agreement sets forth the general terms and conditions of your use of the https://profilephoto.app as well as the products and/or services purchased or accessed through this Site (the "Services").Whether you are simply browsing or using this Site or purchase Services, your use of this Site and your electronic acceptance of this Agreement signifies that you have read, understand, acknowledge and agree to be bound by this Agreement our <Link href={RouteIds.Privacy} textDecoration='underline'>Privacy policy</Link>. The terms "we", "us" or "our" shall refer to Company. The terms "you", "your", "User" or "customer" shall refer to any individual or entity who accepts this Agreement, uses our Site, has access or uses the Services. Nothing in this Agreement shall be deemed to confer any third-party rights or benefits.
Company may, in its sole and absolute discretion, change or modify this Agreement, and any policies or agreements which are incorporated herein, at any time, and such changes or modifications shall be effective immediately upon posting to this Site. Your use of this Site or the Services after such changes or modifications have been made shall constitute your acceptance of this Agreement as last revised.
IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT AS LAST REVISED, DO NOT USE (OR CONTINUE TO USE) THIS SITE OR THE SERVICES.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          2. ELIGIBILITY
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        This Site and the Services are available only to Users who can form legally binding contracts under applicable law. By using this Site or the Services, you represent and warrant that you are (i) at least eighteen (18) years of age, (ii) otherwise recognized as being able to form legally binding contracts under applicable law, and (iii) are not a person barred from purchasing or receiving the Services found under the laws of the Georgia or other applicable jurisdiction.
If you are entering into this Agreement on behalf of a company or any corporate entity, you represent and warrant that you have the legal authority to bind such corporate entity to the terms and conditions contained in this Agreement, in which case the terms "you", "your", "User" or "customer" shall refer to such corporate entity. If, after your electronic acceptance of this Agreement, Company finds that you do not have the legal authority to bind such corporate entity, you will be personally responsible for the obligations contained in this Agreement.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          3. RULES OF USER CONDUCT
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        By using this Site You acknowledge and agree that:
    <br/>- Your use of this Site, including any content you submit, will comply with this Agreement and all applicable local, state, national and international laws, rules and regulations.
    <br/>
    <br/>You will not use this Site in a manner that:
    <br/>
    <br/>- Is illegal, or promotes or encourages illegal activity;
    <br/>- Promotes, encourages or engages in child pornography or the exploitation of children;
    <br/>- Promotes, encourages or engages in terrorism, violence against people, animals, or property;
    <br/>- Promotes, encourages or engages in any spam or other unsolicited bulk email, or computer or network hacking or cracking;
    <br/>- Infringes on the intellectual property rights of another User or any other person or entity;
    <br/>- Violates the privacy or publicity rights of another User or any other person or entity, or breaches any duty of confidentiality that you owe to another User or any other person or entity;
    <br/>- Interferes with the operation of this Site;
    <br/>- Contains or installs any viruses, worms, bugs, Trojan horses, Cryptocurrency Miners or other code, files or programs designed to, or capable of, using many resources, disrupting, damaging, or limiting the functionality of any software or hardware.
    <br/>
    <br/>You will not:
    <br/>
    <br/>- copy or distribute in any medium any part of this Site, except where expressly authorized by Company,
    <br/> - modify or alter any part of this Site or any of its related technologies,
    <br/>- access Companies Content (as defined below) or any technology or means other than through this Site itself.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          4. INTELLECTUAL PROPERTY
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        All rights to the photos uploaded to our Service or generated using our Services belong to you. We do not use your generated photos in advertisements or expose your name or photos anywhere without your explicit consent. You retain all rights to the generated photos and are responsible for their use.
In addition to the rules above, the provisions in this Section apply specifically to your use of Companies Content posted to Site. Companies Content on this Site, including without limitation the text, software, scripts, source code, API, graphics, photos  (except your uploaded and generated photos), sounds, music, videos and interactive features and the trademarks, service marks and logos contained therein ("Companies Content"), are owned by or licensed to the Company in perpetuity, and are subject to copyright, trademark, and/or patent protection.
Companies Content is provided to you "as is", "as available" and "with all faults" for your information and personal, non-commercial use only and may not be downloaded, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any purposes whatsoever without the express prior written consent of Company. No right or license under any copyright, trademark, patent, or other proprietary right or license is granted by this Agreement.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          5. LINKS TO THIRD-PARTY WEBSITES
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        This Site may contain links to third-party websites that are not owned or controlled by Company. Company assumes no responsibility for the content, terms and conditions, privacy policies, or practices of any third-party websites. In addition, Company does not censor or edit the content of any third-party websites. By using this Site you expressly release Company from any and all liability arising from your use of any third-party website. Accordingly, Company encourages you to be aware when you leave this Site and to review the terms and conditions, privacy policies, and other governing documents of each other website that you may visit.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          6. DISCLAIMER OF REPRESENTATIONS AND WARRANTIES
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THIS SITE SHALL BE AT YOUR OWN RISK AND THAT THIS SITE ARE PROVIDED "AS IS", "AS AVAILABLE" AND "WITH ALL FAULTS". COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, DISCLAIM ALL WARRANTIES, STATUTORY, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT (I) THE ACCURACY, COMPLETENESS, OR CONTENT OF THIS SITE, (II) THE ACCURACY, COMPLETENESS, OR CONTENT OF ANY SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, AND/OR (III) THE SERVICES FOUND AT THIS SITE OR ANY SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, AND COMPANY ASSUMES NO LIABILITY OR RESPONSIBILITY FOR THE SAME.
IN ADDITION, YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT NO ORAL OR WRITTEN INFORMATION OR ADVICE PROVIDED BY COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, AND THIRD-PARTY SERVICE PROVIDERS WILL (I) CONSTITUTE LEGAL OR FINANCIAL ADVICE OR (II) CREATE A WARRANTY OF ANY KIND WITH RESPECT TO THIS SITE OR THE SERVICES FOUND AT THIS SITE, AND USERS SHOULD NOT RELY ON ANY SUCH INFORMATION OR ADVICE.
THE FOREGOING DISCLAIMER OF REPRESENTATIONS AND WARRANTIES SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW, and shall survive any termination or expiration of this Agreement or your use of this Site or the Services found at this Site.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          7. LIMITATION OF LIABILITY
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        IN NO EVENT SHALL COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AND ALL THIRD PARTY SERVICE PROVIDERS, BE LIABLE TO YOU OR ANY OTHER PERSON OR ENTITY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING ANY DAMAGES THAT MAY RESULT FROM (I) THE ACCURACY, COMPLETENESS, OR CONTENT OF THIS SITE, (II) THE ACCURACY, COMPLETENESS, OR CONTENT OF ANY SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, (III) THE SERVICES FOUND AT THIS SITE OR ANY SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, (IV) PERSONAL INJURY OR PROPERTY DAMAGE OF ANY NATURE WHATSOEVER, (V) THIRD-PARTY CONDUCT OF ANY NATURE WHATSOEVER, (VI) ANY INTERRUPTION OR CESSATION OF SERVICES TO OR FROM THIS SITE OR ANY SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, (VII) ANY VIRUSES, WORMS, BUGS, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR FROM THIS SITE OR ANY SITES LINKED (THROUGH HYPERLINKS, BANNER ADVERTISING OR OTHERWISE) TO THIS SITE, (VIII) ANY USER CONTENT OR CONTENT THAT IS DEFAMATORY, HARASSING, ABUSIVE, HARMFUL TO MINORS OR ANY PROTECTED CLASS, PORNOGRAPHIC, "X-RATED", OBSCENE OR OTHERWISE OBJECTIONABLE, AND/OR (IX) ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF THIS SITE OR THE SERVICES FOUND AT THIS SITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL OR EQUITABLE THEORY, AND WHETHER OR NOT COMPANY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
IN ADDITION, You SPECIFICALLY ACKNOWLEDGE AND agree that any cause of action arising out of or related to this Site or the Services found at this Site must be commenced within one (1) year after the cause of action accrues, otherwise such cause of action shall be permanently barred.
THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW, AND shall survive any termination or expiration of this Agreement or your use of this Site or the Services found at this Site.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          8. DATA TRANSFER
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        If you are visiting this Site from a country other than the country in which our servers are located, your communications with us may result in the transfer of information across international boundaries. By visiting this Site and communicating electronically with us, you consent to such transfers.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          9. AVAILABILITY OF WEBSITE
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        Subject to the terms and conditions of this Agreement and our policies, we shall use commercially reasonable efforts to attempt to provide this Site on 24/7 basis. You acknowledge and agree that from time to time this Site may be inaccessible for any reason including, but not limited to, periodic maintenance, repairs or replacements that we undertake from time to time, or other causes beyond our control including, but not limited to, interruption or failure of telecommunication or digital transmission links or other failures.
You acknowledge and agree that we have no control over the availability of this Site on a continuous or uninterrupted basis, and that we assume no liability to you or any other party with regard thereto.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          10. PAYMENTS AND REFUNDS
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        You agree to pay any and all prices and fees due for Services purchased or obtained at this Site at the time you order the Services.
All charges and payments are non-refundable, non-cancellable, and non-creditable, even if your Services are suspended, terminated, or transferred prior to the end of the Services term.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          11. COMPLIANCE WITH LOCAL LAWS
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        Company makes no representation or warranty that the content available on this Site are appropriate in every country or jurisdiction, and access to this Site from countries or jurisdictions where its content is illegal is prohibited. Users who choose to access this Site are responsible for compliance with all local laws, rules and regulations.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          12. GOVERNING LAW
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        This Agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation shall be governed by and construed in accordance with the laws of Georgia, to the exclusion of conflict of law rules.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          13. DISPUTE RESOLUTION
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        Any controversy or claim arising out of or relating to these Terms of Services will be settled by binding arbitration. Any such controversy or claim must be arbitrated on an individual basis, and must not be consolidated in any arbitration with any claim or controversy of any other party. The arbitration must be conducted in Georgia, and judgment on the arbitration award may be entered into any court having jurisdiction thereof.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          14. TITLES AND HEADINGS
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        The titles and headings of this Agreement are for convenience and ease of reference only and shall not be utilized in any way to construe or interpret the agreement of the parties as otherwise set forth herein.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          15. SEVERABILITY
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        Each covenant and agreement in this Agreement shall be construed for all purposes to be a separate and independent covenant or agreement. If a court of competent jurisdiction holds any provision (or portion of a provision) of this Agreement to be illegal, invalid, or otherwise unenforceable, the remaining provisions (or portions of provisions) of this Agreement shall not be affected thereby and shall be found to be valid and enforceable to the fullest extent permitted by law.
        </Text>
        <Text textStyle={TextStyle.h4} color={Colors.black} mb={4}>
          16. SEVERABILITY
        </Text>
        <Text textStyle={TextStyle.body} color={Colors.black} mb={6}>
        If you have any questions about this Agreement, please contact us by email or regular mail at the following address:
        <br/>profilephotohelp@gmail.com
        </Text>
      </Flex>
    </DefaultLayout>
  )
}

/* eslint-disable max-classes-per-file */
/* eslint-disable default-param-last */

import i18n from './i18n';

export class ApplicationError extends Error {
  constructor(message, errors) {
    super(message || i18n.t('errors.unknown'));
    this.name = this.constructor.name;
    this.message = message || i18n.t('errors.unknown');
    this.errors = errors;
  }
}

export class NotAuthorizedError extends ApplicationError {
  constructor(message) {
    super(message || i18n.t('errors.notAuth'));
  }
}

export const getErrorMessage = (error, returnUndefined) => {
  if (error instanceof ApplicationError) {
    return error.message;
  }
  if (returnUndefined) {
    return undefined;
  }
  return i18n.t('errors.unknown');
};

import { VStack } from '@chakra-ui/react';

import IntroSection from './IntroSection';
import ReviewsSection from './ReviewsSection';
import PricesSection from './PricesSection';
import LastSection from './LastSection';
import DefaultLayout from '../../components/DefaultLayout';
import LandingTopBar from '../../components/LandingTopBar';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Colors } from '../../theme';
import HowToWork from './HowToWork';
import NotRealPhotos from './NotRealPhotos';
import Quality from './Quality';
import Styles from './Styles';
import Privacy from './Privacy';
import FAQ from './FAQ';
import { WelcomeSections } from '../../helpers/RouteIds';

const Welcome = () => {
  const pricesRef = useRef(null);
  const casesRef = useRef(null);
  const howToUseRef = useRef(null);
  const faqRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();
  const hash = location.hash;
  const pathname = location.pathname;

  useEffect(() => {
    const scrollToRef = (ref) => {
      ref.current && ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      navigate(pathname);
    };

    if (hash === WelcomeSections.UserReviews) {
      scrollToRef(casesRef);
    }
    if (hash === WelcomeSections.Prices) {
      scrollToRef(pricesRef);
    }
    if (hash === WelcomeSections.HowItWorks) {
      scrollToRef(howToUseRef);
    }
    if (hash === WelcomeSections.FAQ) {
      scrollToRef(faqRef);
    }
  }, [hash, navigate, pathname]);

  return (
    <DefaultLayout
      overflowX='visible'
      topBar={
        <LandingTopBar
          backgroundColor={Colors.white}
        />
      }
      hasFooter
    >
      <VStack spacing='120px' py='64px' w='100%'>
        <IntroSection />
        <HowToWork propRef={howToUseRef} />
        <NotRealPhotos />
        <ReviewsSection propRef={casesRef} />
        <Quality />
        <Styles />
        <PricesSection propRef={pricesRef} />
        <Privacy />
        <FAQ propRef={faqRef} />
        <LastSection />
      </VStack>
    </DefaultLayout>
  );
};

export default Welcome;
import { Grid, GridItem, HStack, Image, Text, VStack } from '@chakra-ui/react';
import Star from '../../resources/assets/common/star.svg';
import Arrow from '../../resources/assets/landing/reviewSection/arrow.svg';
import { Colors, TextStyle } from '../../theme';
import { useTranslation } from 'react-i18next';

import FirstPersonReal_1 from '../../resources/assets/landing/reviewSection/firstPerson/in1.jpg';
import FirstPersonReal_2 from '../../resources/assets/landing/reviewSection/firstPerson/in2.jpg';
import FirstPersonReal_3 from '../../resources/assets/landing/reviewSection/firstPerson/in3.jpg';
import FirstPersonReal_4 from '../../resources/assets/landing/reviewSection/firstPerson/in4.jpg';
import FirstPersonGen_1 from '../../resources/assets/landing/reviewSection/firstPerson/out3.jpg';
import FirstPersonGen_2 from '../../resources/assets/landing/reviewSection/firstPerson/out4.jpg';
import FirstPersonGen_3 from '../../resources/assets/landing/reviewSection/firstPerson/out7.jpg';
import FirstPersonGen_4 from '../../resources/assets/landing/reviewSection/firstPerson/out8.jpg';
import FirstPersonAvatar from '../../resources/assets/landing/reviewSection/firstPerson/out7.jpg';

import SecondPersonReal_1 from '../../resources/assets/landing/reviewSection/secondPerson/in0.jpg';
import SecondPersonReal_2 from '../../resources/assets/landing/reviewSection/secondPerson/in1.jpg';
import SecondPersonReal_3 from '../../resources/assets/landing/reviewSection/secondPerson/in2.jpg';
import SecondPersonReal_4 from '../../resources/assets/landing/reviewSection/secondPerson/in6.jpg';
import SecondPersonGen_1 from '../../resources/assets/landing/reviewSection/secondPerson/out0.jpg';
import SecondPersonGen_2 from '../../resources/assets/landing/reviewSection/secondPerson/out6.jpg';
import SecondPersonGen_3 from '../../resources/assets/landing/reviewSection/secondPerson/out9.jpg';
import SecondPersonGen_4 from '../../resources/assets/landing/reviewSection/secondPerson/out10.jpg';
import SecondPersonAvatar from '../../resources/assets/landing/reviewSection/secondPerson/out5.jpg';

import ThirdPersonReal_1 from '../../resources/assets/landing/reviewSection/thirdPerson/in1.jpg';
import ThirdPersonReal_2 from '../../resources/assets/landing/reviewSection/thirdPerson/in2.jpg';
import ThirdPersonReal_3 from '../../resources/assets/landing/reviewSection/thirdPerson/in3.jpg';
import ThirdPersonReal_4 from '../../resources/assets/landing/reviewSection/thirdPerson/in4.jpg';
import ThirdPersonGen_1 from '../../resources/assets/landing/reviewSection/thirdPerson/out1.jpg';
import ThirdPersonGen_2 from '../../resources/assets/landing/reviewSection/thirdPerson/out4.jpg';
import ThirdPersonGen_3 from '../../resources/assets/landing/reviewSection/thirdPerson/out3.jpg';
import ThirdPersonGen_4 from '../../resources/assets/landing/reviewSection/thirdPerson/out2.jpg';
import ThirdPersonAvatar from '../../resources/assets/landing/reviewSection/thirdPerson/out1.jpg';

import FourthPersonReal_1 from '../../resources/assets/landing/reviewSection/fourthPerson/in1.jpg';
import FourthPersonReal_2 from '../../resources/assets/landing/reviewSection/fourthPerson/in3.jpg';
import FourthPersonReal_3 from '../../resources/assets/landing/reviewSection/fourthPerson/in4.jpg';
import FourthPersonReal_4 from '../../resources/assets/landing/reviewSection/fourthPerson/in5.jpg';
import FourthPersonGen_1 from '../../resources/assets/landing/reviewSection/fourthPerson/out1.jpg';
import FourthPersonGen_2 from '../../resources/assets/landing/reviewSection/fourthPerson/out2.jpg';
import FourthPersonGen_3 from '../../resources/assets/landing/reviewSection/fourthPerson/out3.jpg';
import FourthPersonGen_4 from '../../resources/assets/landing/reviewSection/fourthPerson/out4.jpg';
import FourthPersonAvatar from '../../resources/assets/landing/reviewSection/fourthPerson/out4.jpg';

const HeaderItem = ({ avatar, name, profession }) => (
  <HStack spacing='16px' align='center'>
    <Image
      src={avatar}
      w='60px'
      h='60px'
      borderRadius='30px'
    />
    <VStack 
      spacing='8px' 
      alignItems='flex-start'
    >
      <HStack spacing={0}>
        { [...Array(5).keys()].map ((index) => (
          <Image
            src={Star}
            width='24px'
            height='24px'
            key={index}
          />
        )) }
      </HStack>
      <Text
        textStyle={TextStyle.caption1}
        color={Colors.black}
      >
        <Text as='span' textStyle={TextStyle.caption1Bold}>
          { name }
        </Text>
        { profession }
      </Text>
    </VStack>
  </HStack>
);

const RealImages = ({ images }) => (
  <HStack w='85%' spacing='8px' align='flex-end'>
    { images.map((image, index) => (
      <Image
        w='100%'
        aspectRatio={1}
        h='auto'
        src={image}
        borderRadius='16px'
        key={index}
        minW={0}
      />
    )) }
    <Image
      w={19.51}
      h={19.14}
      src={Arrow}
    />
  </HStack>
);

const GeneratedImages = ({ images }) => (
  <Grid
    gap='12px'
    templateColumns='repeat(2, 1fr)'
    w='100%'
  >
    { images.map((image, index) => (
      <GridItem
        colSpan={1}
        key={index}
      >
        <Image
          src={image}
          aspectRatio={1}
          height='auto'
          width='100%'
          borderRadius='16px'
        />
      </GridItem>
    )) }
  </Grid>
);

const ReviewItem = ({ avatar, name, profession, description, realImages, generatedImages }) => (
  <VStack 
    spacing={{ base: '12px', lg: '32px'}}
    alignItems='flex-start'
    h={{ base: 'auto', sm: '100%' }}
  >
    <HeaderItem
      avatar={avatar}
      name={name}
      profession={profession}
    />
    <Text
      textStyle={TextStyle.body}
      color={Colors.black}
      flexGrow={{ base: 0, sm: 1 }}
    >
      { description }
    </Text>
    <RealImages
      images={realImages}
    />
    <GeneratedImages
      images={generatedImages}
    />
  </VStack>
);

const breakpointMobile = 'lg';

const ReviewsSection = ({ propRef }) => {
  const { t } = useTranslation();

  const people = [
    {
      realImages: [SecondPersonReal_1, SecondPersonReal_2, SecondPersonReal_3, SecondPersonReal_4],
      generatedImages: [SecondPersonGen_1, SecondPersonGen_2, SecondPersonGen_3, SecondPersonGen_4],
      name: t('welcome.reviewSection.firstPerson.name'),
      profession: t('welcome.reviewSection.firstPerson.profession'),
      description: t('welcome.reviewSection.firstPerson.description'),
      avatar: SecondPersonAvatar
    },
    {
      realImages: [FirstPersonReal_1, FirstPersonReal_2, FirstPersonReal_3, FirstPersonReal_4],
      generatedImages: [FirstPersonGen_1, FirstPersonGen_2, FirstPersonGen_3, FirstPersonGen_4],
      name: t('welcome.reviewSection.secondPerson.name'),
      profession: t('welcome.reviewSection.secondPerson.profession'),
      description: t('welcome.reviewSection.secondPerson.description'),
      avatar: FirstPersonAvatar
    },
    {
      realImages: [ThirdPersonReal_1, ThirdPersonReal_2, ThirdPersonReal_3, ThirdPersonReal_4],
      generatedImages: [ThirdPersonGen_1, ThirdPersonGen_2, ThirdPersonGen_3, ThirdPersonGen_4],
      name: t('welcome.reviewSection.thirdPerson.name'),
      profession: t('welcome.reviewSection.thirdPerson.profession'),
      description: t('welcome.reviewSection.thirdPerson.description'),
      avatar: ThirdPersonAvatar
    },
    {
      realImages: [FourthPersonReal_1, FourthPersonReal_2, FourthPersonReal_3, FourthPersonReal_4],
      generatedImages: [FourthPersonGen_1, FourthPersonGen_2, FourthPersonGen_3, FourthPersonGen_4],
      name: t('welcome.reviewSection.fourthPerson.name'),
      profession: t('welcome.reviewSection.fourthPerson.profession'),
      description: t('welcome.reviewSection.fourthPerson.description'),
      avatar: FourthPersonAvatar
    }
  ];

  return (
    <VStack
      w='100%'
      spacing='72px'
    >
      <Text
        textAlign='center'
        color={Colors.black}
        textStyle={TextStyle.h2Bold}
        ref={(ref) => propRef.current = ref}
      >
        { t('welcome.reviewSection.title') }
      </Text>
      <Grid
        w='100%'
        columnGap={{ base: '8px', md: '16px', xl: '20px' }}
        rowGap='32px'
        templateColumns={{ base: 'repeat(4, 1fr)', sm: 'repeat(8, 1fr)', [breakpointMobile]: 'repeat(12, 1fr)' }}
      >
        { people.map((person, index) => (
          <GridItem
            key={index}
            colSpan={{ base: 4, [breakpointMobile]: 3 }}
          >
            <ReviewItem
              { ...person }
            />
          </GridItem>
        )) }
      </Grid>
    </VStack>
  );
};

export default ReviewsSection;
import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, HStack, Link, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Colors, TextStyle } from '../theme';
import Logo from './Logo';
import { defaultScreenPadding, maxWidth } from '../helpers/utils';
import { useTranslation } from 'react-i18next';
import FilledButton from './FilledButton';
import RouteIds from '../helpers/RouteIds';
import WebAndMobileLayout from './WebAndMobileLayout';
import { useRef } from 'react';

const ItemLink = ({ href, text, onClick }) => (
  <Link
    textStyle={TextStyle.body}
    color={Colors.black}
    href={href}
    onClick={onClick}
  >
    { text }
  </Link>
);

const Links = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <ItemLink
        onClick={onClick}
        text={t('headerLanding.howItWorks')}
        href={RouteIds.HowItWorks}
      />
      <ItemLink
        onClick={onClick}
        text={t('headerLanding.userCases')}
        href={RouteIds.UserReviews}
      />
      <ItemLink
        onClick={onClick}
        text={t('headerLanding.price')}
        href={RouteIds.Prices}
      />
      <ItemLink
        onClick={onClick}
        text={t('headerLanding.faq')}
        href={RouteIds.FAQ}
      />
    </>
  );
}

const Web = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  return (
    <>
      <HStack spacing='120px'>
        <HStack spacing='36px'>
          <Logo />
          <Text
            textStyle={TextStyle.h4Bold}
            color={Colors.black}
          >
            Profile Photo
          </Text>
        </HStack>
        <HStack spacing='24px'>
          <Links />
        </HStack>
      </HStack>
      <HStack spacing='120px'>
        <FilledButton
          text={t('headerLanding.createButton')}
          onClick={() => navigate(RouteIds.Login)}
        />
      </HStack>
    </>
  );
};

const Mobile = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);
  const navigate = useNavigate();

  return (
    <>
      <HStack spacing='36px'>
        <Logo />
        <Text
          textStyle={TextStyle.h4Bold}
          color={Colors.black}
        >
          Profile Photo
        </Text>
      </HStack>
      <Button
        variant='outline'
        onClick={onOpen}
      >
        { t('headerLanding.menu') }
      </Button>
      <Drawer
        size='xs'
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader />
          <DrawerBody>
            <VStack
              w='100%'
              spacing='16px'
              alignItems='flex-start'
            >
              <FilledButton
                w='100%'
                text={t('headerLanding.createButton')}
                onClick={() => navigate(RouteIds.Login)}
              />
              <Links onClick={onClose} />
            </VStack>
          </DrawerBody>

          <DrawerFooter>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
};

const LandingTopBar = ({ backgroundColor }) => (
  <Flex w='100%' backgroundColor={backgroundColor} direction='column' as='header' position='fixed' zIndex={200}>
    <Flex
      maxW={maxWidth}
      direction='row'
      justifyContent='space-between'
      as='nav'
      px={defaultScreenPadding}
      h='60px'
      alignItems='center'
      alignSelf='center'
      w='100%'
    >
      <WebAndMobileLayout
        breakpoint='lg'
        web={<Web />}
        mobile={<Mobile />}
      />
    </Flex>
  </Flex>
);

export default LandingTopBar;
import { Image, Skeleton } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';

const LazyImage = (props) => {
  const [inView, setInView] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const callback = useCallback(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.disconnect();
        }
      });
    },
    []
  );

  const observer = useMemo(
    () => new IntersectionObserver(callback),
    [callback]
  );

  const skeletonRef = (el) => {
    if (el) observer.observe(el);
  };
  const imageRef = (el) => {
    if (el) el.addEventListener("load", () => setIsLoading(false));
  };

  const imageProps = useMemo(() => {
    if (isLoading) {
      return {
        ...props,
        display: "none",
      };
    }

    return props;
  }, [isLoading, props]);

  return (
    <>
      {(!inView || isLoading) && <Skeleton ref={skeletonRef} {...props} speed={1.5} />}
      {inView && <Image ref={imageRef} {...imageProps} />}
    </>
  );
};

export default LazyImage;
import { Box, Button, Flex, Input, Text, VStack } from '@chakra-ui/react';
import DefaultLayout from '../../components/DefaultLayout';
import LandingTopBar from '../../components/LandingTopBar';
import { Colors, TextStyle } from '../../theme';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState, applyCodeFromEmail, googleLogin, requestCodeForEmail, setEmailForSignIn } from '../../slices/auth.slice';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import RouteIds from '../../helpers/RouteIds';
import ReactGA from 'react-ga4';
import { isWebview } from '../../helpers/utils';

let prevCode;

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth.authState);
  const isSignIsLoading = useSelector((state) => state.auth.isSignInLoading);
  const email = useSelector((state) => state.auth.emailSignIn.email);
  const isEmailSent = useSelector((state) => state.auth.emailSignIn.isEmailSent);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const authCode = params.get('auth_code');
  const isInsideWebview = isWebview();

  const login = useGoogleLogin({
    onSuccess: tokenResponse => dispatch(googleLogin(tokenResponse)),
    onError: () => ReactGA.event("login_google_failed"),
    flow: 'auth-code'
  });

  useEffect(() => {
    if (authCode === null || prevCode === authCode) {
      return;
    }
    prevCode = authCode;
    dispatch(applyCodeFromEmail(authCode));
  }, [authCode, dispatch])

  useEffect(() => {
    if (authState === AuthState.auth) {
      navigate(RouteIds.App, { replace: true });
    }
  }, [navigate, authState]);

  return (
    <DefaultLayout
      backgroundColor={Colors.lightBlue}
      topBar={<LandingTopBar backgroundColor={Colors.white} />}
      hasFooter
      footerColor={Colors.white}
    >
      <Flex w='100%' justifyContent='center' alignItems='center' flexGrow={1} direction='column'>
        <VStack
          spacing='36px'
          p={{ base: '20px', sm: '40px' }}
          borderRadius='16px'
          backgroundColor={Colors.white}
        >
          <VStack spacing='12px'>
            <Text
              textStyle={TextStyle.h2Bold}
              color={Colors.black}
              textAlign='center'
            >
              { t('login.title') }
            </Text>
            <Text
              textStyle={TextStyle.footnote}
              color={Colors.black}
            >
              { t('login.detail') }
            </Text>
          </VStack>
          <VStack
            w='100%'
            spacing='24px'
          >
            {
              !isInsideWebview && (
                <>
                  <Button
                    variant='outline'
                    color={Colors.lightGray}
                    w='100%'
                    onClick={() => {
                      ReactGA.event("login_process_google_button");
                      login();
                    }}
                    isLoading={isSignIsLoading}
                  >
                    { t('login.signInWithGoogle') }
                  </Button>
                  <Box
                    w='100%'
                    h='2px'
                    backgroundColor={Colors.lightBlue}
                  />
                </>
              )
            }
            <VStack
              w='100%'
              spacing='16px'
            >
              {
                isEmailSent
                ? (
                  <Text
                    textStyle={TextStyle.body}
                    textColor={Colors.black}
                    textAlign='center'
                  >
                    { t('login.signInWithEmail.requestCodeSuccess.info', { email }) }
                  </Text>
                )
                : (
                  <>
                    <Input
                      px={4}
                      py={2}
                      backgroundColor={Colors.lightBlue}
                      w='100%'
                      borderRadius='0.375rem'
                      flexGrow={1}
                      variant='unstyled'
                      placeholder={t('welcome.enterEmail')}
                      textStyle={TextStyle.body}
                      colorScheme={Colors.black}
                      value={email}
                      onChange={(event) => dispatch(setEmailForSignIn(event.target.value))}
                    />
                    <Button
                      variant='outline'
                      color={Colors.lightGray}
                      w='100%'
                      onClick={() => {
                        dispatch(requestCodeForEmail());
                      }}
                      isLoading={isSignIsLoading}
                    >
                      { t('login.signInWithEmail') }
                    </Button>
                  </>
                )
              }
            </VStack>
          </VStack>
          
        </VStack>
      </Flex>
    </DefaultLayout>
  )
};

export default Login;
import { Avatar, Flex, HStack, Menu, MenuButton, MenuItem, MenuList, Show, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { Colors, TextStyle } from '../theme';
import Logo from './Logo';
import { defaultScreenPadding, maxWidth } from '../helpers/utils';
import { useTranslation } from 'react-i18next';
import { signOut } from '../slices/auth.slice';
import RouteIds from '../helpers/RouteIds';

const UserButton = ({ user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Menu>
      <MenuButton
        variant="ghost"
        // _hover={{ bg: useColorMode("gray.100", "gray.100") }}
      >
        <HStack spacing='16px'>
          <Avatar
            name={user.name}
            size='xs'
            src={user.profilePic}
          />
          <Show above='sm'>
            <Text
              textStyle={TextStyle.body}
              maxW='25vw'
              color={Colors.black}
              noOfLines={1}
              whiteSpace='nowrap'
              overflow='hidden'
              textOverflow='ellipsis'
            >
              { user.name }
            </Text>
          </Show>
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => dispatch(signOut())}>
          <Text textAlign='right' textStyle={TextStyle.body} color={Colors.black}>
            { t('topBar.signOut') }
          </Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const TopBar = ({ logoHref, backgroundColor }) => {
  const user = useSelector((state) => state.users.mainUser);

  return (
    <Flex w='100%' backgroundColor={backgroundColor} direction='column'>
      <Flex
        maxW={maxWidth}
        direction='row'
        justifyContent='space-between'
        as='nav'
        px={defaultScreenPadding}
        h='60px'
        alignItems='center'
        alignSelf='center'
        w='100%'
      >
        <Logo href={logoHref || RouteIds.App} />
        {
          user && (<UserButton user={user} />)
        }
      </Flex>
    </Flex>
  );
};

export default TopBar;
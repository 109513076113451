import { useTranslation } from 'react-i18next';
import DefaultLayout from '../../components/DefaultLayout';
import TopBar from '../../components/TopBar';
import { Colors, TextStyle } from '../../theme';
import { Box, Flex, HStack, Icon, IconButton, Image, SimpleGrid, Skeleton, Spinner, Switch, Text, VStack } from '@chakra-ui/react';
import { BiDownload } from 'react-icons/bi';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import FilledButton from '../../components/FilledButton';
import LazyImage from '../../components/LazyImage';
import { useDispatch, useSelector } from 'react-redux';
import { downloadPhoto, likePhoto, setCompletedProject, shareProject } from '../../slices/projects.slice';
import RouteIds from '../../helpers/RouteIds';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from '../../components/Toast';
import _ from 'lodash';

const PhotoItem = ({ image, isMyProject }) => {
  const dispatch = useDispatch();

  return (
    <Box
      width='100%'
      h='auto'
      aspectRatio={0.75}
      position='relative'
      data-item={true}
    >
      <LazyImage
        src={image.url}
        width='100%'
        objectFit='cover'
      />
      {
        isMyProject && (
          <>
            <Icon
              cursor='pointer'
              position='absolute'
              w='24px'
              h='24px'
              as={ image.isLiked ? AiFillHeart : AiOutlineHeart }
              color={ image.isLiked ? Colors.red : Colors.white }
              top='16px'
              right='16px'
              onClick={() => dispatch(likePhoto(image))}
            />
            <Flex
              p='8px'
              backgroundColor='rgba(0,0,0,0.6)'
              justifyContent='flex-end'
              w='100%'
              bottom={0}
              left={0}
              right={0}
              position='absolute'
            >
              <IconButton
                boxSizing='content-box'
                isLoading={image.isDownloading}
                variant="ghost"
                color={Colors.white}
                icon={<Icon as={BiDownload} h='70%' />}
                onClick={ () => dispatch(downloadPhoto(image)) }
                size='sm'
              />
            </Flex>
          </>

        )
      }
    </Box>
  );
};

const ImagesWithText = ({ images, text, isMyProject }) => (
  <VStack
    spacing='24px'
    w='100%'
    alignItems='flex-start'
  >
    {
      text && (
        <Text
          textStyle={TextStyle.h4Bold}
          color={Colors.black}
        >
          { text }
        </Text>
      )
    }
    <SimpleGrid
      w='100%'
      columns={{ base: 2, md: 4 }}
      spacing='20px'
    >
      {
        images.map((image, index) => (
          <PhotoItem
            key={image.id}
            image={image}
            isMyProject={isMyProject}
          />
        ))
      }
    </SimpleGrid>
  </VStack>
);

const Share = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const completedProject = useSelector((state) => state.projects.completedProject);
  const isPublic = completedProject.isPublic;

  return (
    <VStack
      spacing='4px'
      alignSelf='flex-start'
      align='flex-start'
    >
      <HStack
        spacing='8px'
      >
        <Text
          textStyle={TextStyle.bodyBold}
          color={Colors.black}
        >
          { t('completedProject.share.title') }
        </Text>
        <Switch
          size='md'
          isChecked={isPublic}
          isReadOnly={completedProject.isUpdatingPublic}
          onChange={() => dispatch(shareProject())}
        />
        {
          isPublic && (
            <Text
              textStyle={TextStyle.footnote}
              cursor='pointer'
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                toast({
                  title: t('completedProject.share.linkWasCopied'),
                  status: 'success',
                  duration: 3000
                });
              }}
            >
              { t('completedProject.share.visible.copyLink') }
            </Text>
          )
        }
      </HStack>
      <Text
        textStyle={TextStyle.footnote}
        color={Colors.lightGray}
      >
        { t(isPublic ? 'completedProject.share.visible.subtitle' : 'completedProject.share.hidden.subtitle') }
      </Text>
    </VStack>
  );
};

const Images = ({ images, isMyProject }) => {
  const likedImages = images.filter((item) => item.isLiked);
  const { t } = useTranslation();

  return (
    <VStack
      spacing='60px'
      w='100%'
    >
      {
        likedImages.length > 0 && (
          <ImagesWithText
            images={likedImages}
            text={t('project.completed.likedPhotos')}
            isMyProject={isMyProject}
          />
        )
      }
      <ImagesWithText
        images={images}
        text={ likedImages.length > 0 && t('project.completed.allPhotos')}
        isMyProject={isMyProject}
      />
    </VStack>
  );
};

const CommonContainer = ({ children }) => (
  <DefaultLayout
    backgroundColor={Colors.lightBlue}
    topBar={<TopBar logoHref='/app' backgroundColor={Colors.white} />}
    hasFooter
    footerColor={Colors.white}
  >
    { children }
  </DefaultLayout>
);

const CommonContainerWithCenter = ({ children }) => (
  <CommonContainer>
     <Flex
      w='100%'
      grow={1}
      justify='center'
      align='center'
    >
      { children }
    </Flex>
  </CommonContainer>
)

const LoadingScreen = ({ text }) => (
  <CommonContainerWithCenter>
    <VStack spacing='16px'>
      <Text
        textStyle={TextStyle.body}
        color={Colors.black}
        textAlign='center'
      >
        { text }
      </Text>
      <Spinner color={Colors.black} size='lg' />
    </VStack>
  </CommonContainerWithCenter>
);

const ErrorScreen = ({ text }) => (
  <CommonContainerWithCenter>
    <VStack spacing='16px'>
      <Text
        textStyle={TextStyle.body}
        color={Colors.black}
        textAlign='center'
      >
        { text }
      </Text>
    </VStack>
  </CommonContainerWithCenter>
);

const CompletedProject = () => {
  const { t } = useTranslation();

  const completedProject = useSelector((state) => state.projects.completedProject);
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const images = completedProject.generatedPhotos;

  useEffect(() => {
    if (completedProject.id === projectId || !dispatch) {
      return;
    }
    dispatch(setCompletedProject(projectId));
  }, [projectId, dispatch, completedProject]);

  if (completedProject.isLoading || _.isUndefined(completedProject.id)) {
    return (<LoadingScreen text={t('project.loading')} />);
  }

  if (completedProject.errorLoading) {
    return (<ErrorScreen text={completedProject.errorLoading} />);
  }

  return (
    <DefaultLayout
      backgroundColor={Colors.lightBlue}
      topBar={<TopBar logoHref={RouteIds.App} backgroundColor={Colors.white} />}
      hasFooter
      footerColor={Colors.white}
    >
      <VStack
        w='100%'
        spacing='64px'
      >
        <VStack
          w='100%'
          spacing='24px'
        >
          {
            completedProject.isMyProject && (
              <Share />
            )
          }
          <Images images={images} isMyProject={completedProject.isMyProject} />
        </VStack>
        {
          completedProject.isMyProject && (
            <VStack
              spacing='16px'
            >
              <Text
                textStyle={TextStyle.body}
                color={Colors.black}
              >
                { t('project.completed.title') }
              </Text>
              <FilledButton
                text={t('project.completed.shareFeedback')}
                onClick={() => window.open('mailto:profilephotohelp@gmail.com')}
              />
            </VStack>
          )
        }
      </VStack>
    </DefaultLayout>
  );
};

export default CompletedProject;
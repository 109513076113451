import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ReactGA from 'react-ga4';

import api from '../services/api';
import { setUserId } from '../services/amplitude';

const initialState = {
  mainUser: undefined
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadMainUser.fulfilled, (state, { payload: item }) => {
        state.mainUser = { ...item };
      });
  },
});

export const loadMainUser = createAsyncThunk(
  'user/loadMainUser',
  async () => {
    const { data: { result } } = await api.get('/v1/users');
    ReactGA.set({ userId: result.id });
    setUserId(result.id);
    return result;
  },
);

export default usersSlice.reducer;

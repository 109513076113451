import { Grid, GridItem, Text, VStack, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IoImages, IoColorWand, IoHappySharp } from 'react-icons/io5';

import { Colors, TextStyle } from '../../theme';
import FullWidthContainer from '../../components/FullWidthContainer';

const breakpointMobile = 'lg';

const Item = ({ image, title, detail }) => (
  <VStack spacing='16px' px='32px'>
    <Icon
      w='64px'
      h='64px'
      as={image}
    />
    <Text
      textStyle={TextStyle.h4Bold}
      color={Colors.black}
    >
      { title }
    </Text>
    <Text
      textAlign='center'
      textStyle={TextStyle.body}
      color={Colors.black}
    >
      { detail }
    </Text>
  </VStack>
);

const HowToWork = ({ propRef }) => {
  const { t } = useTranslation();

  return (
    <FullWidthContainer backgroundColor={Colors.lightBlue}>
      <Grid
        w='100%'
        ref={(ref) => propRef.current = ref}
        columnGap={{ base: '8px', md: '16px', xl: '20px' }}
        rowGap='48px'
        templateColumns={{ base: 'repeat(4, 1fr)', [breakpointMobile]: 'repeat(12, 1fr)' }}
        py={{ base: '32px', [breakpointMobile]: '40px' }}
      >
        <GridItem
          colSpan={4}
        >
          <Item
            image={IoImages}
            title={t('welcome.howToWork.item_1.title')}
            detail={t('welcome.howToWork.item_1.detail')}
          />
        </GridItem>
        <GridItem
          colSpan={4}
        >
          <Item
            image={IoColorWand}
            title={t('welcome.howToWork.item_2.title')}
            detail={t('welcome.howToWork.item_2.detail')}
          />
        </GridItem>
        <GridItem
          colSpan={4}
        >
          <Item
            image={IoHappySharp}
            title={t('welcome.howToWork.item_3.title')}
            detail={t('welcome.howToWork.item_3.detail')}
          />
        </GridItem>
      </Grid>
    </FullWidthContainer>
  );
};

export default HowToWork;
import { Flex } from '@chakra-ui/react';
import { defaultScreenPadding, maxWidth } from '../helpers/utils';

const FullWidthContainer = ({ backgroundColor, children }) => (
  <Flex
    w='100vw'
    backgroundColor={backgroundColor}
    direction='column'
  >
    <Flex
      maxW={maxWidth}
      px={defaultScreenPadding}
      alignSelf='center'
      w='100%'
      direction='column'
    >
      <Flex
        w='100%'
        py={4}
      >
        { children }
      </Flex>
    </Flex>
  </Flex>
);

export default FullWidthContainer;
import { Image, Link, Text } from '@chakra-ui/react';
import { Colors, TextStyle } from '../theme';

import LogoImage from '../resources/assets/common/logo.png';

const Logo = ({ href }) => (
  <Link href={href || '/'}>
    <Image
      w='60px'
      h='60px'
      src={LogoImage}
    />
  </Link>
);

export default Logo;
import { Grid, GridItem, Text, VStack, Icon, Flex, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IoShieldHalfSharp, IoFingerPrint, IoLockClosed } from 'react-icons/io5';

import { Colors, TextStyle } from '../../theme';
import { defaultScreenPadding, minusDefaultScreenPadding } from '../../helpers/utils';
import FullWidthContainer from '../../components/FullWidthContainer';

const breakpointMobile = 'lg';

const Item = ({ image, title }) => (
  <VStack spacing='24px' px='32px'>
    <Icon
      w='64px'
      h='64px'
      color={Colors.white}
      as={image}
    />
    <Text
      textStyle={TextStyle.h4}
      color={Colors.white}
      textAlign='center'
    >
      { title }
    </Text>
  </VStack>
);

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <FullWidthContainer backgroundColor={Colors.black}>
      <Grid
        w='100%'
        columnGap={{ base: '8px', md: '16px', xl: '20px' }}
        rowGap='48px'
        templateColumns={{ base: 'repeat(4, 1fr)', [breakpointMobile]: 'repeat(12, 1fr)' }}
        py={{ base: '32px', [breakpointMobile]: '40px' }}
        alignContent='top'
      >
        <GridItem
          colSpan={4}
        >
          <Item
            image={IoShieldHalfSharp}
            title={t('welcome.privacy.item_1.title')}
          />
        </GridItem>
        <GridItem
          colSpan={4}
        >
          <Item
            image={IoFingerPrint}
            title={t('welcome.privacy.item_2.title')}
          />
        </GridItem>
        <GridItem
          colSpan={4}
        >
          <Item
            image={IoLockClosed}
            title={t('welcome.privacy.item_3.title')}
          />
        </GridItem>
      </Grid>
    </FullWidthContainer>
  );
};

export default Privacy;
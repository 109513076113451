import { Box, Center, HStack, Icon, Image, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IoCheckbox } from 'react-icons/io5';


import i18n from '../helpers/i18n';
import { Colors, TextStyle } from '../theme';
import FilledButton from './FilledButton';
import { PackageType } from '../models/PurchaseItems';

const TypeTariff = {
  [PackageType.normal]: {
    name: i18n.t('tariffs.regular.namePack'),
    price: i18n.t('tariffs.regular.price'),
    salePrice: i18n.t('tariffs.regular.salePrice'),
    texts: [
      i18n.t('tariffs.regular.text_1'),
      i18n.t('tariffs.regular.text_2'),
      i18n.t('tariffs.regular.text_3'),
    ]
  },
  [PackageType.lite]: {
    name: i18n.t('tariffs.lite.namePack'),
    price: i18n.t('tariffs.lite.price'),
    salePrice: i18n.t('tariffs.lite.salePrice'),
    texts: [
      i18n.t('tariffs.lite.text_1'),
      i18n.t('tariffs.lite.text_2'),
      i18n.t('tariffs.lite.text_3'),
    ]
  },
  [PackageType.pro]: {
    name: i18n.t('tariffs.pro.namePack'),
    price: i18n.t('tariffs.pro.price'),
    salePrice: i18n.t('tariffs.pro.salePrice'),
    texts: [
      i18n.t('tariffs.pro.text_1'),
      i18n.t('tariffs.pro.text_2'),
      i18n.t('tariffs.pro.text_3'),
    ]
  }
};

const PriceTariff = ({
  isSandbox,
  packageType,
  borderColor,
  border,
  backgroundColor,
  onClick,
  activeColor,
  badge,
  hasSale
}) => {
  const { t } = useTranslation();

  return (
    <VStack
      spacing='32px'
      p={{ base: '30px', sm: '40px' }}
      borderRadius='32px'
      border={border}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      position='relative'
    >
      <VStack
        spacing='8px'
        alignItems='center'
      >
        <Text
          textStyle={TextStyle.bodyBold}
          color={Colors.lightGray}
        >
          { TypeTariff[packageType].name.toUpperCase() }
        </Text>
        <VStack spacing='-4px'>
          {
            hasSale && (
              <HStack spacing='8px'>
                <Box px='8px' py='4px' backgroundColor={Colors.lightGray} borderRadius='8px'>
                  <Text
                    textStyle={TextStyle.caption1Bold}
                    color={Colors.white}
                  >
                    30% SALE
                  </Text>
                </Box>
                <Text
                  textDecoration='line-through'
                  textStyle={TextStyle.body}
                  color={Colors.black}
                >
                  { TypeTariff[packageType].price }
                </Text>
              </HStack>
            )
          }
          <Text
            textStyle={TextStyle.h2Bold}
            color={activeColor || Colors.black}
          >
            { isSandbox ? '0$' : (hasSale ? TypeTariff[packageType].salePrice : TypeTariff[packageType].price) }
          </Text>
        </VStack>
        <Text
          textStyle={TextStyle.body}
          color={isSandbox ? Colors.blue : Colors.lightGray}
        >
          { isSandbox ? t('tariffs.sandbox.price') : t('common.price.perPerson') }
        </Text>
      </VStack>
      <VStack spacing='16px' alignItems='flex-start'>
        {
          TypeTariff[packageType].texts.map((item, index) => (
            <HStack spacing='16px' key={index}>
              <Icon
                as={IoCheckbox}
                color={activeColor || Colors.black}
                w='24px'
                h='24px'
              />
              <Text textStyle={TextStyle.body} color={Colors.black}>
                { item }
              </Text>
            </HStack>
          ))
        }        
      </VStack>
      {
          isSandbox && (
            <HStack spacing='16px'>
              <Text textStyle={TextStyle.footnote} color={Colors.lightGray}>
                { t('tariffs.sandbox.description') }
              </Text>
            </HStack>
          )
        }
      <FilledButton
        width='100%'
        backgroundColor={activeColor}
        text={t('common.price.buy.format', { text: TypeTariff[packageType].name })}
        onClick={onClick}
      />
      {
        badge && (
          <Center
            position='absolute'
            top='-14px'
            left={0}
            right={0}
          >
            <Center
              h='28px'
              border={border}
              borderColor={borderColor}
              backgroundColor={backgroundColor || Colors.white}
              borderRadius='14px'
              px='16px'
            >
              <Text
                textStyle={TextStyle.bodyBold}
                color={activeColor || Colors.black}
              >
                { badge }
              </Text>
            </Center>
          </Center>
        )
      }
    </VStack>
  );
};

export default PriceTariff;
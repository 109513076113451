import Constants from '../config/constants';



/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export const WelcomeSections = {
  Prices: '#prices',
  FAQ: '#faq',
  UserReviews: '#reviews',
  HowItWorks: '#howitworks',
};

const RouteIds = {
  Welcome: '/',
  Prices: `/${WelcomeSections.Prices}`,
  FAQ: `/${WelcomeSections.FAQ}`,
  UserReviews: `/${WelcomeSections.UserReviews}`,
  HowItWorks: `/${WelcomeSections.HowItWorks}`,
  Login: '/login',
  Privacy: '/privacy',
  Terms: '/terms',
  Project: '/p/:projectId',
  ProjectWithId: (id) => `/p/${id}`,
  CompleteProjectWithId: (id) => `/completed/${id}`,
  NewProject: `/p/${Constants.NEW_PROJECT}`,
  CompletedProject: '/completed/:projectId',
  App: '/app',
};

export const PageTitles = {
  [RouteIds.App]: 'Application',
  [RouteIds.Welcome]: 'Landing',
  [RouteIds.Login]: 'Login',
  [RouteIds.UserReviews]: 'Landing Reviews',
  [RouteIds.Privacy]: 'Privacy',
  [RouteIds.Terms]: 'Terms',
};

export default RouteIds;
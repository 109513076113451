import * as amplitude from '@amplitude/analytics-browser';

amplitude.init('0e4715396483b70e806b290c39cd1bf1', { defaultTracking: true });

export const logEvent = (name, parameters) => {
  amplitude.logEvent(name, parameters);
};

export const setUserId = (userId) => {
  amplitude.setUserId(userId);
};
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import heic2any from 'heic2any';
import ReactGA from 'react-ga4';

import api from '../services/api';
import { ProjectStatus, nextProjectStatus, nextAfterWaitingForPay } from '../models/ProjectStatus';
import Constants from '../config/constants';
import i18n from '../helpers/i18n';
import { ApplicationError } from '../helpers/errors';
import { loadMainUser } from './user.slice';
import { asyncTimeout, isMobileOperatingSystem, is_iOS } from '../helpers/utils';
import { toast } from '../components/Toast';
import { compressImage } from '../helpers/compressImage';
import { saveAs } from 'file-saver';

export const MINIMUM_COUNT_PHOTOS = 10;
const MIN_DIMENSION_WIDTH = 850;
const MIN_DIMENSION_HEIGHT = 1100;

const initialState = {
  projects: {
  },
  isLoadingList: false,
  list: [],
  editingProject: {
    name: '',
    gender: null,
    isLoading: false,
    errorLoading: null,
    errorUpdating: null,
    isUpdating: false,
    visibleStatus: null,
    visibleId: null,
    userPhotos: [],
    generatedPhotos: [],
    hasUnloadedPhotos: false,
    isLoadingAfterPay: false,
    isErrorAfterLoadingAfterPay: false,
    isPhotoConverting: false
  },
  completedProject: {
    isLoading: false,
    errorLoading: null,
    isMyProject: false,
    isUpdatingPublic: false
  }
};

const controllers = {};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setName: (state, { payload }) => {
      state.editingProject.name = payload || '';
    },
    setGender: (state, { payload }) => {
      state.editingProject.gender = payload || null;
    },
    setVisibleStatus: (state, { payload }) => {
      state.editingProject.visibleStatus = payload;
    },
    setPhotoProgress: (state, { payload: { photoId, progress } }) => {
      const index = state.editingProject.userPhotos.findIndex((item) => item.localId === photoId);
      if (index < 0) {
        return;
      }
      state.editingProject.userPhotos[index].progress = progress;
    },
    setIsPhotoConveration: (state, { payload }) => {
      state.editingProject.isPhotoConverting = payload;
    },
    addPhotos: (state, { payload: photos }) => {
      state.editingProject.userPhotos.push(...photos);
      state.editingProject.hasUnloadedPhotos = true;
    },
    closeProjectScreen: (state) => {
      const localPhotos = state.editingProject.userPhotos.filter((item) => item.preview);
      localPhotos.forEach((photo) => {
        URL.revokeObjectURL(photo.preview);
        const controller = controllers[photo.localId];
        controller && controller.abort();
      });
      state.editingProject.userPhotos = state.editingProject.userPhotos.filter((item) => !item.preview);
    },
    removePhoto: (state, { payload: photo }) => {
      const photos = [...state.editingProject.userPhotos];
      const index = photos.findIndex((item) => _.isUndefined(photo.url) ? item.localId === photo.localId : photo.url === item.url);
      const controller = controllers[photo.localId];
      controller && controller.abort();
      if (index < 0) {
        return;
      }
      console.log(`revoke ${photos[index].preview}`);
      photos[index].preview && URL.revokeObjectURL(photos[index].preview);
      photos.splice(index, 1);
      state.editingProject.userPhotos = photos;
      state.editingProject.hasUnloadedPhotos = state.editingProject.userPhotos.some((item) => !item.url);
    },
    clearErrorAfterPayment: (state) => {
      state.editingProject.isErrorAfterLoadingAfterPay = false;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loadMainUser.fulfilled, (state, { payload: result }) => {
        result.projects.forEach((item) => {
          state.projects[item.id] = { ...item };
        });
        if (!_.isUndefined(state.completedProject.id)) {
          state.completedProject.isMyProject = Object.keys(state.projects).includes(state.completedProject.id);
        }
        state.list = result.projects.map(({ id }) => id);
        state.isLoadingList = false;
      })
      .addCase(loadMainUser.pending, (state) => {
        state.isLoadingList = true;
      })
      .addCase(loadMainUser.rejected, (state) => {
        state.isLoadingList = false;
      })

      .addCase(setCompletedProject.pending, (state, { meta: { arg: id } }) => {
        state.completedProject.id = id;
        state.completedProject.isLoading = true;
        state.completedProject.errorLoading = null;
      })
      .addCase(setCompletedProject.fulfilled, (state, { payload: result }) => {
        state.completedProject = {
          ...state.completedProject,
          ...result,
          isLoading: false,
          isMyProject: Object.keys(state.projects).includes(result.id)
        };
      })
      .addCase(setCompletedProject.rejected, (state) => {
        state.completedProject.errorLoading = i18n.t('completedProject.errors.notFound');
        state.completedProject.isLoading = false;
      })

      .addCase(shareProject.pending, (state) => {
        state.completedProject.isUpdatingPublic = true;
      })
      .addCase(shareProject.fulfilled, (state, { payload: result }) => {
        state.completedProject.isPublic = result.isPublic;
        state.completedProject.isUpdatingPublic = false;
      })
      .addCase(shareProject.rejected, (state) => {
        state.completedProject.isUpdatingPublic = false;
        toast({
          title: i18n.t('project.errors.update.title'),
          description: i18n.t('project.errors.update.description'),
          status: 'error',
          duration: 5000
        });
      })

      .addCase(setActiveProject.pending, (state, { meta: { arg: id } }) => {
        state.editingProject.visibleId = id;
        state.editingProject.isLoading = true;
      })
      .addCase(setActiveProject.fulfilled, (state, { payload: result }) => {
        if (!_.isUndefined(result.id)) {
          state.projects[result.id] = { ...result };
        }
        state.editingProject = { userPhotos: [], ...result, isLoading: false };

        state.editingProject.visibleStatus = state.editingProject.status;
      })
      .addCase(setActiveProject.rejected, (state) => {
        state.editingProject.errorLoading = i18n.t('project.errors.notFound');
        state.editingProject.isLoading = false;
      })

      .addCase(updateProject.pending, (state) => {
        state.editingProject.isUpdating = true;
      })
      .addCase(updateProject.fulfilled, (state, { payload: result }) => {
        state.projects[result.id] = { ...result };
        state.editingProject = { ...state.editingProject, ...result, isUpdating: false };
        state.editingProject.visibleStatus = nextProjectStatus(state.editingProject.visibleStatus);
        // if (state.editingProject.visibleStatus === ProjectStatus.waitingForPay && state.editingProject.status === ProjectStatus.waitingForSubmit) {
        //   state.editingProject.visibleStatus = state.editingProject.status;
        // }
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.editingProject.errorUpdating = i18n.t('chat.error.notFound');
        state.editingProject.isUpdating = false;
        toast({
          title: i18n.t('project.errors.update.title'),
          description: action.error.message || i18n.t('project.errors.update.description'),
          status: 'error',
          duration: 5000
        });
      })

      .addCase(loadAfterPay.pending, (state) => {
        state.editingProject.isLoadingAfterPay = true;
      })
      .addCase(loadAfterPay.fulfilled, (state, { payload: result }) => {
        state.projects[result.id] = { ...result };
        state.editingProject = { ...state.editingProject, ...result, isLoadingAfterPay: false };
        state.editingProject.visibleStatus = state.editingProject.status;
        toast({
          title: i18n.t('project.payment.successful.title'),
          description: i18n.t('project.payment.successful.description'),
          status: 'success',
          duration: 10000
        });
        ReactGA.event('payment_success');
      })
      .addCase(loadAfterPay.rejected, (state) => {
        state.editingProject.isErrorAfterLoadingAfterPay = true;
        state.editingProject.isLoadingAfterPay = false;
      })

      .addCase(uploadPhoto.pending, (state, { meta: { arg } }) => {
        const index = state.editingProject.userPhotos.findIndex((item) => item.localId === arg.localId);
        state.editingProject.userPhotos[index].isUploading = true;
        state.editingProject.userPhotos[index].progress = 0;
        state.editingProject.userPhotos[index].error = undefined;
        state.editingProject.hasUnloadedPhotos = true;
      })
      .addCase(uploadPhoto.fulfilled, (state, { payload: result, meta: { arg } }) => {
        const index = state.editingProject.userPhotos.findIndex((item) => item.localId === arg.localId);
        controllers[arg.localId] = undefined;
        if (index < 0) {
          return;
        }
        if (state.editingProject.userPhotos[index].preview) {
          URL.revokeObjectURL(state.editingProject.userPhotos[index].preview);
        }
        state.editingProject.userPhotos[index] = { ...result };
        state.editingProject.hasUnloadedPhotos = state.editingProject.userPhotos.some((item) => !item.url);
      })
      .addCase(uploadPhoto.rejected, (state, { meta: { arg }, error }) => {
        const index = state.editingProject.userPhotos.findIndex((item) => item.localId === arg.localId);
        controllers[arg.localId] = undefined;
        if (index < 0) {
          return;
        }
        state.editingProject.userPhotos[index].isUploading = false;
        state.editingProject.userPhotos[index].progress = 0;
        state.editingProject.userPhotos[index].error = error.message;
        toast({
          title: i18n.t('project.errors.upload.title'),
          description: i18n.t('project.errors.upload.description'),
          status: 'error',
          duration: 4000
        });
        state.editingProject.hasUnloadedPhotos = true;
      })

      .addCase(downloadPhoto.pending, (state, { meta: { arg } }) => {
        state.completedProject.generatedPhotos.forEach((item) => {
          if (item.url === arg.url) {
            item.isDownloading = true;
          }
        });
      })
      .addCase(downloadPhoto.fulfilled, (state, { meta: { arg }, payload }) => {
        const index = state.completedProject.generatedPhotos.findIndex((item) => item.url === arg.url);
        if (index < 0) {
          return;
        }
        state.completedProject.generatedPhotos[index] = { ...payload };
        const idProject = state.completedProject.id;
        state.projects[idProject].generatedPhotos[index] = { ...payload };
      })
      .addCase(downloadPhoto.rejected, (state, { meta: { arg } }) => {
        state.completedProject.generatedPhotos.forEach((item) => {
          if (item.url === arg.url) {
            item.isDownloading = false;
          }
        });
      })

      .addCase(likePhoto.pending, (state, { meta: { arg } }) => {
        state.completedProject.generatedPhotos.forEach((item) => {
          if (item.url === arg.url) {
            item.isLiked = !item.isLiked;
          }
        });
      })
      .addCase(likePhoto.fulfilled, (state, { meta: { arg }, payload }) => {
        const index = state.completedProject.generatedPhotos.findIndex((item) => item.url === arg.url);
        if (index < 0) {
          return;
        }
        state.completedProject.generatedPhotos[index] = { ...payload };
        const idProject = state.completedProject.id;
        state.projects[idProject].generatedPhotos[index] = { ...payload };
      })
      .addCase(likePhoto.rejected, (state, { meta: { arg } }) => {
        state.completedProject.generatedPhotos.forEach((item) => {
          if (item.url === arg.url) {
            item.isLiked = !item.isLiked;
          }
        });
      })
      ;
  },
});

export const { setName, addPhotos, setPhotoProgress, closeProjectScreen, removePhoto, setVisibleStatus, clearErrorAfterPayment, setIsPhotoConveration, setGender } = projectsSlice.actions;

export const makeSelectProjects = () => {
  const selectProjectsByIds = createSelector(
    [(state) => state.projects.projects, (_, ids) => ids],
    (projects, ids) => ids.map((id) => projects[id]),
  );
  return selectProjectsByIds;
};

export const setCompletedProject = createAsyncThunk(
  'projects/setCompletedProject',
  async (projectId, apiThunk) => {
    const { projects: { projects } } = apiThunk.getState();
    let activeProject;
    if (projectId in projects) {
      activeProject = { ...projects[projectId] };
    } else {
      const { data: { result } } = await api.get(`/v1/projects/${projectId}`);
      activeProject = result;
    }
    return activeProject;
  },
);

export const setActiveProject = createAsyncThunk(
  'projects/setActiveProject',
  async (projectId, apiThunk) => {
    const { projects: { projects } } = apiThunk.getState();
    let activeProject;
    if (projectId === Constants.NEW_PROJECT) {
      activeProject = { status: ProjectStatus.projectDetails, visibleId: Constants.NEW_PROJECT, name: '' };
    } else if (projectId in projects) {
      activeProject = { ...projects[projectId], visibleId: projectId };
    } else {
      const { data: { result } } = await api.get(`/v1/projects/${projectId}`);
      activeProject = result;
    }
    return activeProject;
  },
);

export const loadAfterPay = createAsyncThunk(
  'projects/loadAfterPay',
  async (_p, apiThunk) => {
    const { projects: { editingProject } } = apiThunk.getState();
    let result;
    try {
      const { data: { result: _result } } = await api.get(`/v1/projects/${editingProject.id}`);
      result = _result;
    } catch { }
    if (result && result.status === nextAfterWaitingForPay) {
      return result;
    } else {
      await asyncTimeout(1500);
    }
    try {
      const { data: { result: _result } } = await api.get(`/v1/projects/${editingProject.id}`);
      result = _result;
    } catch { }
    if (result && result.status === nextAfterWaitingForPay) {
      return result;
    } else {
      throw new ApplicationError('No processing status')
    }
  },
);

export const updateProject = createAsyncThunk(
  'projects/updateProject',
  async (_p, apiThunk) => {
    const { projects: { editingProject } } = apiThunk.getState();
    if (!editingProject.name) {
      throw new ApplicationError('You have to input name');
    }

    const newStatus = editingProject.status === editingProject.visibleStatus && nextProjectStatus(editingProject.status);
    const userPhotos = editingProject.userPhotos;
    const updatedData = {
      name: editingProject.name,
      status: newStatus || editingProject.status,
      gender: editingProject.gender,
      userPhotos
    };
    if (userPhotos.length > 0 && userPhotos.length < MINIMUM_COUNT_PHOTOS) {
      throw new Error(i18n.t('project.uploadPhoto.minimumPhotosFormat', { count: MINIMUM_COUNT_PHOTOS }));
    }
    if (_.isUndefined(editingProject.id)) {
      const { data: { result } } = await api.post('/v1/projects', updatedData);
      ReactGA.event('create_project');
      return result;
    }
    const { data: { result } } = await api.put(`/v1/projects/${editingProject.id}`, updatedData);
    return result;
  },
);

const getExtensionOfFile = (file) => (file.name ? file.name.split('.').pop() : file.path.split('.').pop()).toLowerCase();

const isHeicFile = (file) => getExtensionOfFile(file) === 'heic' || getExtensionOfFile(file) === 'heif';

export const downloadPhoto = createAsyncThunk(
  'project/downloadPhoto',
  async (image, apiThunk) => {
    const { projects: { completedProject } } = apiThunk.getState();
    const array = image.url.split('/');
    const name = array[array.length - 1];
    
    let result;
    try {
      const { data } = await api.post(`/v1/projects/${completedProject.id}/${image.id}/download`);
      result = data.result;
    } catch {
      ;
    }

    result = result || { ...image };

    if (is_iOS() && navigator.share) {
      let imageResult = await fetch(image.url);
      imageResult = await imageResult.blob();
      const data = {
        files: [new File([imageResult], name)]
      };
      if (navigator.canShare && navigator.canShare(data)) {
        navigator.share(data).catch(() => {});
        return result;
      }
    }
    saveAs(image.url, name);
    return result;
  }
);

export const likePhoto = createAsyncThunk(
  'project/likePhoto',
  async ({ id, isLiked }, apiThunk) => {
    const { projects: { completedProject } } = apiThunk.getState();
    if (isLiked) {
      const { data: { result } } = await api.delete(`/v1/projects/${completedProject.id}/${id}/like`);
      return result;
    }
    const { data: { result } } = await api.post(`/v1/projects/${completedProject.id}/${id}/like`);
    return result;
  }
);

export const shareProject = createAsyncThunk(
  'project/shareOrHideProject',
  async (_parameter, apiThunk) => {
    const { projects: { completedProject } } = apiThunk.getState();
    if (completedProject.isPublic) {
      const { data: { result } } = await api.delete(`/v1/projects/${completedProject.id}/share`);
      return result;
    }
    const { data: { result } } = await api.post(`/v1/projects/${completedProject.id}/share`);
    return result;
  }
);

export const addNewPhotos = createAsyncThunk(
  'projects/addNewPhotos',
  async (files, apiThunk) => {
    const { projects: { editingProject } } = apiThunk.getState();

    let updatedFiles = [...files];

    const allLocal = editingProject.userPhotos.every((item) => !item.isUploading && !item.url);

    apiThunk.dispatch(setIsPhotoConveration(true));

    const convertedImagesPromises = updatedFiles
      .filter((file) => isHeicFile(file))
      .map((file) => heic2any({ blob: file, toType: 'image/jpeg' }));

    const convertedImages = await Promise.all(convertedImagesPromises);
    let convertIndex = 0;
    updatedFiles = updatedFiles.map(
      (file) => isHeicFile(file) ? new File([convertedImages[convertIndex++]], `${uuidv4()}.jpg`) : file
    );
    
    // const promises = updatedFiles.map(compressImage);
    // let result = [];
    // const chunks = _.chunk(promises, 1);
    // console.log(chunks)
    // let i = 0;
    // for (const chunk of chunks) {
    //   console.log(`Upload chunk with ${i++} ${chunk}`)
    //   const res = await Promise.all(chunk);
    //   console.log(res);
    //   result.push(...res);
    // }
    // updatedFiles = result;

    // updatedFiles = await Promise.all(promises);

    apiThunk.dispatch(setIsPhotoConveration(false));

    const addedPhotos = updatedFiles.map((file) => {
      const image = new Image();
      const preview = URL.createObjectURL(file);
      const promise = new Promise(resolve => {
        image.addEventListener('load', () => {
          resolve(image);
        });
      });
      image.src = preview;
      return {
        localFile: file,
        localId: uuidv4(),
        preview,
        image,
        promise
      };
    });
    
    await Promise.all(addedPhotos.map((item) => item.promise));

    const normalPhotos = addedPhotos.filter((photo) => photo.image.width >= MIN_DIMENSION_WIDTH && photo.image.height >= MIN_DIMENSION_HEIGHT);
    const badPhotos = addedPhotos.filter((photo) => photo.image.width < MIN_DIMENSION_WIDTH || photo.image.height < MIN_DIMENSION_HEIGHT);

    badPhotos.forEach((item) => {
      URL.revokeObjectURL(item.preview);
      toast({
        title: i18n.t('project.uploadPhoto.invalidDimensionPhoto.title', {name: item.localFile.name}),
        status: 'error',
        description: i18n.t('project.uploadPhoto.invalidDimensionPhoto.description', { width: MIN_DIMENSION_WIDTH, height: MIN_DIMENSION_HEIGHT }),
        duration: 8000
      });
    })

    apiThunk.dispatch(addPhotos(normalPhotos));
    if (allLocal) {
      return;
    }
    apiThunk.dispatch(uploadPhotos());
  },
);

export const uploadPhotos = createAsyncThunk(
  'projects/uploadPhotos',
  async (_, apiThunk) => {
    const { projects: { editingProject } } = apiThunk.getState();

    const localPhotos = editingProject.userPhotos.filter((item) => !item.isUploading && !item.url);
    localPhotos.forEach(item => apiThunk.dispatch(uploadPhoto(item)));
  },
);

export const uploadPhoto = createAsyncThunk(
  'projects/uploadPhoto',
  async (item, apiThunk) => {
    const { projects: { editingProject } } = apiThunk.getState();
    const photoFormData = new FormData();

    photoFormData.append('file', item.localFile, item.name);
    // photoFormData.append("file", item.localFile);

    const controller = new AbortController();
    controllers[item.localId] = controller;

    const { data: { result } } = await api.post(
      `/v1/projects/${editingProject.id}/upload`,
      photoFormData,
      {
        onUploadProgress: (progressEvent) => apiThunk.dispatch(setPhotoProgress({
          progress: progressEvent.loaded / progressEvent.total,
          photoId: item.localId
        })),
        signal: controller.signal
      }
    );
    return result;
  },
);

export default projectsSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ReactGA from 'react-ga4';

import api from '../services/api';
import { ApplicationError, NotAuthorizedError } from '../helpers/errors';
import i18n from '../helpers/i18n';
import { loadMainUser } from './user.slice';
import { toast } from '../components/Toast';
import { validateEmail } from '../helpers/utils';
import { setUserId } from '../services/amplitude';

export const AuthState = {
  idle: 'idle',
  loading: 'loading',
  auth: 'auth',
  notAuth: 'notAuth',
};

const initialState = {
  authState: AuthState.idle,
  token: null,
  refreshToken: null,
  isSignInLoading: false,
  errorSignIn: '',
  beta: {
    email: '',
    isSending: false,
    successText: '',
    errorText: ''
  },
  emailSignIn: {
    email: '',
    isEmailSent: false,
    errorText: ''
  }
};

export const Keys = {
  storageAccessToken: 'storageAccessToken',
  storageRefreshToken: 'storageRefreshToken',
  authorizationHeader: 'Authorization',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokens: (state, action) => {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
    },
    setEmailForBeta: (state, action) => {
      state.beta.email = action.payload;
    },
    setEmailForSignIn: (state, action) => {
      state.emailSignIn.email = action.payload;
      state.emailSignIn.errorText = '';
    },
  },
  extraReducers(builder) {
    builder

      .addCase(signUpForBeta.pending, (state) => {
        state.beta.isSending = true;
        state.beta.successText = '';
        state.beta.errorText = '';
      })
      .addCase(signUpForBeta.rejected, (state) => {
        state.beta.isSending = false;
        state.beta.errorText = i18n.t('welcome.failed.title');

        toast({
          title: i18n.t('welcome.failed.title'),
          status: 'error',
          duration: 5000
        });
      })
      .addCase(signUpForBeta.fulfilled, (state) => {
        state.beta.isSending = false;
        state.beta.email = '';
        state.beta.successText = i18n.t('welcome.success.title');

        toast({
          title: i18n.t('welcome.success.title'),
          status: 'success',
          duration: 5000
        });
      })

      .addCase(requestCodeForEmail.pending, (state) => {
        state.isSignInLoading = true;
      })
      .addCase(requestCodeForEmail.rejected, (state) => {
        state.isSignInLoading = false;
        let errorText = undefined;
        if (!validateEmail(state.emailSignIn.email)) {
          errorText = i18n.t('Please enter a valid email');
          state.emailSignIn.errorText = errorText;
        }
        toast({
          title: errorText || i18n.t('welcome.failed.title'),
          status: 'error',
          duration: 5000
        });
      })
      .addCase(requestCodeForEmail.fulfilled, (state) => {
        state.emailSignIn.isEmailSent = true;
        state.isSignInLoading = false;

        toast({
          title: i18n.t('login.signInWithEmail.requestCodeSuccess.title'),
          status: 'success',
          duration: 5000
        });
      })

      .addCase(setupAuth.pending, (state) => {
        state.authState = AuthState.loading;
      })
      .addCase(setupAuth.fulfilled, (state) => {
        state.authState = AuthState.auth;
      })
      .addCase(setupAuth.rejected, (state) => {
        state.authState = AuthState.notAuth;
        state.refreshToken = null;
        state.token = null;
      })

      .addCase(signOut.fulfilled, (state) => {
        state.authState = AuthState.notAuth;
      })

      .addCase(applyCodeFromEmail.pending, (state) => {
        state.errorSignIn = '';
        state.isSignInLoading = true;
      })
      .addCase(applyCodeFromEmail.fulfilled, (state) => {
        state.authState = AuthState.auth;
        state.isSignInLoading = false;
        ReactGA.event("login_success");
      })
      .addCase(applyCodeFromEmail.rejected, (state, { error }) => {
        ReactGA.event("login_backend_failed", { error: error.message });
        state.authState = AuthState.notAuth;
        state.refreshToken = null;
        state.token = null;
        state.isSignInLoading = false;
        state.errorSignIn = i18n.t('common.error.somethingWasWrong');
        toast({
          title: i18n.t('common.error.somethingWasWrong'),
          status: 'error',
          duration: 5000
        });
      })

      .addCase(googleLogin.pending, (state) => {
        state.errorSignIn = '';
        state.isSignInLoading = true;
      })
      .addCase(googleLogin.fulfilled, (state) => {
        state.authState = AuthState.auth;
        state.isSignInLoading = false;
        ReactGA.event("login_success");
      })
      .addCase(googleLogin.rejected, (state, { error }) => {
        ReactGA.event("login_backend_failed", { error: error.message });
        state.authState = AuthState.notAuth;
        state.refreshToken = null;
        state.token = null;
        state.isSignInLoading = false;
        // if (error.code === 401) {
          // state.errorSignIn = i18n.t('login.error.noBetaTester');
        // } else {
        state.errorSignIn = i18n.t('common.error.somethingWasWrong');
        toast({
          title: i18n.t('common.error.somethingWasWrong'),
          status: 'error',
          duration: 5000
        });
        // }
      });
  },
});

export const { setTokens, setEmailForBeta, setEmailForSignIn } = authSlice.actions;

const setHeaderToken = (token) => {
  api.defaults.headers.common[Keys.authorizationHeader] = `Bearer ${token}`;
};

export const signUpForBeta = createAsyncThunk(
  'auth/signUpForBeta',
  async (_, apiThunk) => {
    const { auth: { beta: { email } } } = apiThunk.getState();
    if (email.length === 0) {
      throw new ApplicationError('empty string');
    }
    const { data: { result } } = await api.post('/v1/auth/beta', { email });
    return result;
  },
);

export const requestCodeForEmail = createAsyncThunk(
  'auth/requestCodeForEmail',
  async (_, apiThunk) => {
    ReactGA.event("login_process_email_button");
    const { auth: { emailSignIn: { email } } } = apiThunk.getState();
    if (email.length === 0 || !validateEmail(email)) {
      throw new ApplicationError('empty string');
    }
    const { data: { result } } = await api.post('/v1/auth/email/request-code', { email });
    return result;
  },
);

export const applyCodeFromEmail = createAsyncThunk(
  'auth/applyCodeFromEmail',
  async (code, apiThunk) => {
    const { data: { result } } = await api.post('/v1/auth/email/apply-code', { code });
    
    if (result.accessToken == null || result.refreshToken == null) {
      throw new NotAuthorizedError();
    }
    apiThunk.dispatch(setTokens({
      token: result.accessToken,
      refreshToken: result.refreshToken,
    }));
    localStorage.setItem(Keys.storageAccessToken, result.accessToken);
    localStorage.setItem(Keys.storageRefreshToken, result.refreshToken);

    setHeaderToken(result.accessToken);

    await apiThunk.dispatch(finishAuth()).unwrap();
  },
);

export const setupAuth = createAsyncThunk(
  'auth/setup',
  async (_, apiThunk) => {
    const token = localStorage.getItem(Keys.storageAccessToken);
    const refreshToken = localStorage.getItem(Keys.storageRefreshToken);

    if (token == null || refreshToken == null) {
      throw new NotAuthorizedError();
    }

    apiThunk.dispatch(setTokens({ token, refreshToken }));

    setHeaderToken(token);

    await apiThunk.dispatch(finishAuth()).unwrap();
  },
);

export const googleLogin = createAsyncThunk(
  'auth/googleLogin',
  async ({ code }, apiThunk) => {
    ReactGA.event("login_process_backend");
    const { data: { result } } = await api.post('/v1/auth/google', { code });
    if (result.accessToken == null || result.refreshToken == null) {
      throw new NotAuthorizedError();
    }
    apiThunk.dispatch(setTokens({
      token: result.accessToken,
      refreshToken: result.refreshToken,
    }));
    localStorage.setItem(Keys.storageAccessToken, result.accessToken);
    localStorage.setItem(Keys.storageRefreshToken, result.refreshToken);

    setHeaderToken(result.accessToken);

    await apiThunk.dispatch(finishAuth()).unwrap();
  },
);

export const makeRefreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, apiThunk) => {
    const { auth: { refreshToken } } = apiThunk.getState();
    const { data: { result } } = await api.post(
      '/v1/auth/refresh-token',
      { refreshToken },
      { headers: { [Keys.authorizationHeader]: `Bearer ${refreshToken}` } }
    );
    if (result.accessToken == null || result.refreshToken == null) {
      throw new NotAuthorizedError();
    }
    apiThunk.dispatch(setTokens({
      token: result.accessToken,
      refreshToken: result.refreshToken,
    }));
    localStorage.setItem(Keys.storageAccessToken, result.accessToken);
    localStorage.setItem(Keys.storageRefreshToken, result.refreshToken);

    setHeaderToken(result.accessToken);
  },
);

export const finishAuth = createAsyncThunk(
  'auth/finishAuth',
  async (_, apiThunk) => {
    await Promise.all([
      apiThunk.dispatch(loadMainUser()).unwrap(),
    ]);
  },
);

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (force, apiThunk) => {
    try {
      if (!force) {
        await api.post('/v1/auth/sign-out');
      }
    } catch { }
    delete api.defaults.headers.common[Keys.authorizationHeader];
    localStorage.removeItem(Keys.storageAccessToken);
    localStorage.removeItem(Keys.storageRefreshToken);
    ReactGA.set({ userId: undefined });
    setUserId(undefined);

    apiThunk.dispatch(setTokens({
      token: null,
      refreshToken: null,
    }));
  },
);

export default authSlice.reducer;
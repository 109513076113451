import { Box, Grid, SimpleGrid, Text, VStack, Image, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Colors, TextStyle } from '../../theme';
import FilledButton from '../../components/FilledButton';
import RouteIds from '../../helpers/RouteIds';
import { chakraMultilineText } from '../../helpers/chakraUtils';
import WebAndMobileLayout from '../../components/WebAndMobileLayout';

import img_1 from '../../resources/assets/landing/lastSection/1.jpg';
import img_2 from '../../resources/assets/landing/lastSection/2.jpg';
import img_3 from '../../resources/assets/landing/lastSection/3.jpg';
import img_4 from '../../resources/assets/landing/lastSection/4.jpg';
import img_5 from '../../resources/assets/landing/lastSection/5.jpg';
import img_6 from '../../resources/assets/landing/lastSection/6.jpg';
import img_7 from '../../resources/assets/landing/lastSection/7.jpg';
import img_8 from '../../resources/assets/landing/lastSection/8.jpg';
import img_9 from '../../resources/assets/landing/lastSection/9.jpg';
import img_10 from '../../resources/assets/landing/lastSection/10.jpg';
import img_11 from '../../resources/assets/landing/lastSection/11.jpg';
import img_12 from '../../resources/assets/landing/lastSection/12.jpg';
import img_13 from '../../resources/assets/landing/lastSection/13.jpg';
import img_14 from '../../resources/assets/landing/lastSection/14.jpg';
import FullWidthContainer from '../../components/FullWidthContainer';

const LeftBlock = () => (
  <Box
    w='100%'
    h='400px'
    position='relative'
  >
    <Image
      w='64px'
      h='64px'
      position='absolute'
      src={img_1}
      objectFit='cover'
      borderRadius='32px'
      top='21px'
      right={0}
    />
    <Image
      w='48px'
      h='48px'
      position='absolute'
      src={img_2}
      objectFit='cover'
      borderRadius='24px'
      top='122px'
      left='70px'
    />
    <Image
      w='96px'
      h='96px'
      position='absolute'
      src={img_3}
      objectFit='cover'
      borderRadius='48px'
      top='296px'
      left='10px'
    />
    <Image
      w='48px'
      h='48px'
      position='absolute'
      src={img_4}
      objectFit='cover'
      borderRadius='24px'
      top='19px'
      left='24px'
    />
    <Image
      w='64px'
      h='64px'
      position='absolute'
      src={img_5}
      objectFit='cover'
      borderRadius='32px'
      top='189px'
      right='150px'
    />
    <Image
      w='128px'
      h='128px'
      position='absolute'
      src={img_6}
      objectFit='cover'
      borderRadius='64px'
      top='159px'
      right='135px'
    />
    <Image
      w='48px'
      h='48px'
      position='absolute'
      src={img_7}
      objectFit='cover'
      borderRadius='24px'
      top='290px'
      right='47px'
    />
    <Image
      w='32px'
      h='32px'
      position='absolute'
      src={img_8}
      objectFit='cover'
      borderRadius='16px'
      bottom='10px'
      right='200px'
    />
  </Box>
);

const LeftMobileBlock = () => (
  <Box
    w='100%'
    h='167px'
    position='relative'
  >
    <Image
      w='32px'
      h='32px'
      position='absolute'
      src={img_1}
      objectFit='cover'
      borderRadius='16px'
      top='11px'
      right='0px'
    />
    <Image
      w='24px'
      h='24px'
      position='absolute'
      src={img_2}
      objectFit='cover'
      borderRadius='12px'
      top='13px'
      left='120px'
    />
    <Image
      w='48px'
      h='48px'
      position='absolute'
      src={img_3}
      objectFit='cover'
      borderRadius='24px'
      bottom='10px'
      left='5px'
    />
    <Image
      w='24px'
      h='24px'
      position='absolute'
      src={img_4}
      objectFit='cover'
      borderRadius='12px'
      top='8px'
      left='12px'
    />
    <Image
      w='32px'
      h='32px'
      position='absolute'
      src={img_5}
      objectFit='cover'
      borderRadius='16px'
      top='40px'
      right='60px'
    />
    <Image
      w='64px'
      h='64px'
      position='absolute'
      src={img_6}
      objectFit='cover'
      borderRadius='32px'
      top='60px'
      left='100px'
    />
    <Image
      w='24px'
      h='24px'
      position='absolute'
      src={img_7}
      objectFit='cover'
      borderRadius='12px'
      bottom='34px'
      right='23px'
    />
    <Image
      w='16px'
      h='16px'
      position='absolute'
      src={img_8}
      objectFit='cover'
      borderRadius='8px'
      bottom='5px'
      right='100px'
    />
  </Box>
);

const RightBlock = () => (
  <Box
    w='100%'
    h='400px'
    position='relative'
  >
    <Image
      w='56px'
      h='56px'
      position='absolute'
      src={img_9}
      objectFit='cover'
      borderRadius='28px'
      top='40px'
      left='10px'
    />
    <Image
      w='48px'
      h='48px'
      position='absolute'
      src={img_10}
      objectFit='cover'
      borderRadius='24px'
      bottom='28px'
      left='0px'
    />
    <Image
      w='64px'
      h='64px'
      position='absolute'
      src={img_11}
      objectFit='cover'
      borderRadius='32px'
      top='200px'
      right='11px'
    />
    <Image
      w='96px'
      h='96px'
      position='absolute'
      src={img_12}
      objectFit='cover'
      borderRadius='48px'
      top='13px'
      right='13px'
    />
    <Image
      w='128px'
      h='128px'
      position='absolute'
      src={img_13}
      objectFit='cover'
      borderRadius='64px'
      bottom='120px'
      left='80px'
    />
    <Image
      w='32px'
      h='32px'
      position='absolute'
      src={img_14}
      objectFit='cover'
      borderRadius='16px'
      bottom='19px'
      right='108px'
    />
  </Box>
);

const RightMobileBlock = () => (
  <Box
    w='100%'
    h='167px'
    position='relative'
  >
    <Image
      w='28px'
      h='28px'
      position='absolute'
      src={img_9}
      objectFit='cover'
      borderRadius='14px'
      top='20px'
      left='5px'
    />
    <Image
      w='24px'
      h='24px'
      position='absolute'
      src={img_10}
      objectFit='cover'
      borderRadius='12px'
      bottom='14px'
      left='0px'
    />
    <Image
      w='32px'
      h='32px'
      position='absolute'
      src={img_11}
      objectFit='cover'
      borderRadius='16px'
      bottom='50px'
      right='125px'
    />
    <Image
      w='48px'
      h='48px'
      position='absolute'
      src={img_12}
      objectFit='cover'
      borderRadius='24px'
      top='7px'
      right='7px'
    />
    <Image
      w='64px'
      h='64px'
      position='absolute'
      src={img_13}
      objectFit='cover'
      borderRadius='32px'
      bottom='60px'
      left='76px'
    />
    <Image
      w='16px'
      h='16px'
      position='absolute'
      src={img_14}
      objectFit='cover'
      borderRadius='8px'
      bottom='8px'
      right='51px'
    />
  </Box>
);

const breakpointMobile = 'lg';

const LastSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <FullWidthContainer backgroundColor={Colors.lightBlue}>
      <SimpleGrid
        w='100%'
        columns={{ base: 1, [breakpointMobile]: 3 }}
        spacing='32px'
      >
        <WebAndMobileLayout
          breakpoint={breakpointMobile}
          web={<LeftBlock />}
          mobile={<LeftMobileBlock />}
        />
        <Center>
          <VStack
            w='100%'
            spacing='32px'
          >
            <Text textAlign='center' color={Colors.black} textStyle={TextStyle.h2Bold}>
              { t('welcome.lastSection.title') }
            </Text>
            <Text
              textAlign='center'
              color={Colors.black}
              textStyle={TextStyle.body}
              lineHeight={1.5}
            >
              { chakraMultilineText(t('welcome.lastSection.detail')) }
            </Text>
            <FilledButton
              text={t('welcome.introSection.button')}
              onClick={() => navigate(RouteIds.Login)}
            />
          </VStack>
        </Center>
        <WebAndMobileLayout
          breakpoint={breakpointMobile}
          web={<RightBlock />}
          mobile={<RightMobileBlock />}
        />
      </SimpleGrid>
    </FullWidthContainer>
  );
};

export default LastSection;